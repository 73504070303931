<template>
    <div
        v-if="data.imageGallery.length > 0"
        :class="className"
    >
        <gallery :items="data.imageGallery">
            <template #item="item">
                <asset
                    :asset="item.image"
                />
            </template>
        </gallery>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Gallery from 'components/Gallery'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockImageGallery',
    components: {
        Asset,
        Gallery,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-image-gallery'

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
    },
};

</script>

<style lang="scss">

.b-image-gallery {}

</style>
