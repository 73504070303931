<template>
    <div
        v-if="media"
        class="c-card -instagram"
    >
        <div
            v-if="type === 'VIDEO'"
            class="c-card__media -video"
        >
            <video muted playsinline autoplay loop>
                <source :src="media" type="video/mp4">
            </video>
        </div>
        <asset
            v-else
            :url="media"
            class="c-card__media -img"
        />

        <div class="c-card__content">
            <icon
                class="c-card__palm"
                icon="palm"
            />
            <span class="c-card__title">{{ account.username }}</span>
        </div>

    </div>

</template>

<script>

import Icon from 'objects/Icon'
import Asset from 'objects/Asset'

export default {
    name: 'CardInstagram',
    components: {
        Icon,
        Asset,
    },
    props: {
        type: {
            type: String,
            default: 'IMAGE'
        },
        media: {
            required: true
        },
        account: {
            type: Object,
            default: null
        }
    },
};

</script>

<style lang="scss">

.c-card.-instagram {
    background-color: $color-light;
    border-radius: .5em;
    box-shadow: $box-shadow;
    overflow: hidden;

    .c-card__media {

        video {
            display: block;
            width: 100%;
        }
    }

    .c-card__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: .75em 1em;
    }

    .c-card__title {
        font-size: .8rem;
    }
}

.c-card__palm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
    color: $color-light;
    background-color: $color-dark;
    border-radius: 50%;
}



</style>
