import Vue from 'vue'
import { EventBus } from 'src/event-bus'

import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';

const enter = (args) => {
    EventBus.$emit('cursor-hover-in', args)
}

const leave = (event) => {
    EventBus.$emit('cursor-hover-out')
}

export const view = Vue.directive('cursor', {
    inserted: function($el, bind) {
        const args = {
            type: isEmpty(bind.modifiers) ? 'hover' : kebabCase(Object.keys(bind.modifiers)[0]),
            data: bind.value ? bind.value : {}
        }

        $el.addEventListener('mouseenter', enter.bind(null, args))
        $el.addEventListener('mouseleave', leave)
    },
    unbind: function($el) {
        $el.removeEventListener('mouseenter', enter)
        $el.removeEventListener('mouseleave', leave)
    }
})
