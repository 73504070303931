<template>
    <div
        v-if="hasProjects"
        :class="className"
    >
        <div class="grid__row u-jc--start">
            <div class="grid__col--12 grid__col--5@sm grid__col--3@md">
                <heading
                    v-for="(heading, i) in data.headings"
                    :key="`block-upcoming-heading-${_uid}-${i}`"
                    :title="heading.headline"
                    :tagTitle="heading.headlineTag"
                    :classTitle="heading.headlineClass"
                    :suptitle="heading.suptitle"
                />
            </div>
            <div class="grid__col--12 grid__col--9@md">
                <slider modifier="projects">
                    <div
                        v-for="(project, i) in projects"
                        :key="`block-project-upcoming-${_uid}-${i}`"
                        class="c-slider__slide"
                    >
                        <card-project-upcoming v-bind="project"/>
                    </div>
                </slider>
            </div>
        </div>
    </div>
</template>

<script>

import Heading from 'objects/Heading'
import CardProjectUpcoming from 'components/CardProjectUpcoming'
import Slider from 'components/Slider'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockProjects',
    components: {
        Heading,
        CardProjectUpcoming,
        Slider,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        projects: [],
    }),
    created() {
        let projectIds = this.data.selectEntriesWork

        // Return if empty
        if(projectIds.length === 0) {
            return
        }

        projectIds = projectIds.map(p => p.id)

        this.$store.dispatch('works/preloadMultiple', projectIds)
            .then(projects => {
                this.projects = projects
            })
    },
    computed: {
        className() {
            let classname = 'b-projects-upcoming'

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
        hasProjects() {
            return this.projects.length > 0
        },
    },
};

</script>

<style lang="scss">

.b-projects-upcoming {

    .c-slider {
        margin-top: 2em;
    }
}

</style>
