<template>
    <section
        :id="`section-${data.id}`"
        :class="className"
    >
        <div class="grid">
            <wave
                v-if="data.addWave.includes('has-wave-top')"
                pos="top"
            />
            <component
                v-for="(component, i) in data.children"
                :key="`block-container-children-${i}`"
                :is="component.typeHandle"
                :data="component"
            />
            <wave
                v-if="data.addWave.includes('has-wave-bottom')"
                pos="bottom"
            />
        </div>
    </section>
</template>

<script>

import BlockRow from 'builder/BlockRow'
import Wave from 'objects/Wave'

export default {
    name: 'blockContainer',
    components: {
        BlockRow,
        Wave,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'l-section'

            // if(this.data.sectionFullWidth) {
            //     classname += ' -full-width'
            // }

            if(this.data.bgColor) {
                classname += ` -bg-${this.data.bgColor}`
            }

            return classname
        }
    }
};

</script>

<style lang="scss">


</style>
