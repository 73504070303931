/*
** Debounce function
*/
export const debounce = (callback, delay = 250) => {
    let timeoutId
    return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}


/*
** Round number with n decimals
*/

export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
** Reduced motion var
*/

export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches


/*
** Touch devices
*/

export const isTouch = 'ontouchstart' in document.documentElement


/*
** Add resizEnd event to window
*/

export const resizeEnd = () => {
    const resizeEnd = new Event('resizeEnd');

    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEnd)
    }, 200));
}


/*
** Detect in viewport
*/

export const isInViewport = rect => {
    //only check top and bottom
    const vertInView = (rect.top <= window.innerHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= window.innerWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
};



/*
** Generate grid columns classes
*/
export const gridColumnsClasses = settings => {

    let classname = ' grid__col--12'

    const smCol = settings.smCol
    const mdCol = settings.mdCol
    const lgCol = settings.lgCol
    const textAlign = settings.textAlign


    if(smCol > 0) {
        classname = ` grid__col--${smCol}@sm`
    } else if(lgCol < 8) {
        classname = ' grid__col--6@sm'
    }

    if(mdCol > 0) {
        classname += ` grid__col--${mdCol}@md`
    }

    if(lgCol > 0) {
        classname += ` grid__col--${lgCol}@lg`
    }

    // Text align
    if(textAlign !== 'left' ) {
        classname += ` u-ta-${textAlign}`
    }

    return classname
}

