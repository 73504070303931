<template>
    <div
        v-if="asset"
        :class="className"
    >
        <div class="b-content-image__inner">
            <asset
                :asset="asset"
                class="b-content-image__img"
            />
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'

export default {
    name: 'BlockContentImage',
    components: {
        Asset,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-content-image'

            if(this.data.rounded) {
                classname += ' -rounded'
            }

            return classname
        },
        asset() {

            const asset = this.data.rounded ? this.data.roundedImage: this.data.image

            if(asset.length === 0) {
                return false
            }

            return asset[0]
        },
    },
};

</script>

<style lang="scss">

.b-content-image {

    &.-rounded {

        .b-content-image__inner {
            max-width: 30rem;
            max-height: 30rem;
            margin-right: auto;
            margin-left: auto;
            border-radius: 50%;
        }
    }
}

.b-content-image__img {
    @include img;
}

</style>
