<template>
    <div class="b-instagram | grid__col--12">

        <div class="b-instagram__col">
            <heading
                v-for="(heading, i) in data.headings"
                :key="`block-instagram-content-${_uid}-${i}`"
                :title="heading.headline"
                :tagTitle="heading.headlineTag"
                :fzTitle="heading.headlineSize"
                :suptitle="heading.suptitle"
            />

            <card-instagram
                :media="story.url"
                type="VIDEO"
                :account="account"
                class="-standalone"
            />

        </div>

        <div class="b-instagram__col | b-instagram__grid">
            <div
                v-for="(media, i) in medias.slice(0,4)"
                :key="`instagram-media-${i}`"
                class="b-instagram__item"
            >
                <card-instagram
                    :media="media.media_url"
                    :type="media.media_type"
                    :account="account"
                />
            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'

import Heading from 'objects/Heading'
import CardInstagram from 'components/CardInstagram'

export default {
    name: 'BlockInstagram',
    components: {
        Heading,
        CardInstagram
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState({
            account: state => state.global.instagram.account,
            story: state => state.global.instagram.story,
            medias: state => state.global.instagram.medias
        })
    }
};

</script>

<style lang="scss">

.b-instagram {
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: 100%;

    @media #{md("md")} {
        align-items: end;
        grid-template-columns: 1fr 2fr;
    }
}

.b-instagram__grid {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-card.-standalone {
    margin-top: 1em;

    @media #{md("md", "max")} {
        display: none;
    }
}


</style>
