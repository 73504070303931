<template>
    <vue-vimeo-player
        class="o-vimeo"
        :video-id="id"
        :options="options"
    />
</template>

<script>

import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
    name: 'Vimeo',
    components: {
        vueVimeoPlayer
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        background: {
            type: Boolean,
            default: false,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        loop: {
            type: Boolean,
            default: false,
        },
        controls: {
            type: Boolean,
            default: true,
        },
        playInView: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        options() {
            return {
                loop: this.loop,
                autoplay: this.autoplay,
                controls: this.controls,
                background: this.background,
                playInView: this.playInView,
                responsive: true,
            }
        },
    },
};

</script>

<style lang="scss">

.o-vimeo {
    pointer-events: none;
}

</style>
