<template>
    <section class="l-section">
        <div class="grid">
            <div class="grid__row u-jc-start">
                <div class="grid__col--12 grid__col--9@sm grid__col--5@md">
                    <heading
                        :title="page.headline"
                        classTitle="t-t1"
                    />
                </div>
            </div>

            <div class="p-projects__filters">
                <btn
                    :label="$t('btn.all')"
                    :class="{ 'is-active' : activeFilter === false }"
                    @click.native="updateFilter(false)"
                />
                <btn
                    v-for="(filter, i) in filters"
                    :key="`project-filter-${i}`"
                    :label="filter.title"
                    :class="{ 'is-active' : activeFilter === filter.slug }"
                    @click.native="updateFilter(filter.slug)"
                />
            </div>

            <transition-group
                v-if="orderedProjects.length > 0"
                tag="div"
                class="p-projects__grid"
                :css="false"
                @before-enter="beforeEnter"
                @enter="enter"
                @leave="leave"
            >
                <card-project
                    v-for="(project, i) in orderedProjects"
                    :key="`projects-filtered-${project.id}-${i}`"
                    :featured="project.featured && i % 7 == 0"
                    :title="project.title"
                    :uri="project.uri"
                    :catchphrase="project.catchphrase"
                    :color="project.color"
                    :url="project.url"
                    :image="project.image"
                    :backgroundImage="project.backgroundImage"
                    :hoverText="project.hoverText"
                    :hoverLight="project.hoverLight"
                    :children="project.children"
                    :data-index="i"
                />
            </transition-group>
        </div>
    </section>
</template>

<script>

import Btn from 'components/Btn';
import Heading from 'objects/Heading';
import CardProject from 'components/CardProject';

import { gsap } from 'gsap/all'

export default {
    name: 'Projects',
    components: {
        Btn,
        Heading,
        CardProject,
    },
    props: {
        page: Object,
    },
    data: () => ({
        projects: [],
        activeFilter: false,
    }),
    created() {
        const query = this.$route.query.filter
        this.activeFilter = query ? query : false

        this.$store.dispatch('works/preloadAll')
            .then(projects => {
                this.projects = projects.filter(project => project.isUpcoming === false)
            })
    },
    metaInfo() {
        return {
            title: this.page.seoPage ? this.page.seoPage.title : null
        }
    },
    computed: {
        filters() {
            return this.page.selectCategoryService
        },
        featuredProjects() {
            return this.filteredProjects.filter(p => p.featured === true)
        },
        nonFeaturedProjects() {
            return this.filteredProjects.filter(p => p.featured === false)
        },
        filteredProjects() {

            if(this.activeFilter) {
                return this.projects.filter(p => p.children.find(c => c.selectCategoryService && c.selectCategoryService.find(s => s.slug === this.activeFilter)))
            } else {
                return this.projects
            }
        },
        orderedProjects() {

            const totalProjects = this.filteredProjects.length
            const totalFeatured = this.featuredProjects.length
            const totalNonFeatured = this.nonFeaturedProjects.length

            let featuredAdded = 0
            let nonFeaturedAdded = 0
            const orderedProjects = []
            for (var i = 0; i < totalProjects; i++) {

                // Add featured in every 7th index if exists
                if(i % 7 == 0 && totalFeatured > featuredAdded || totalNonFeatured == nonFeaturedAdded - 1) {

                    if (this.featuredProjects[featuredAdded]) {
                        orderedProjects.push(this.featuredProjects[featuredAdded])
                        featuredAdded++
                    }

                // Add non featured if exists
                } else {

                    if (this.nonFeaturedProjects[nonFeaturedAdded]) {
                        orderedProjects.push(this.nonFeaturedProjects[nonFeaturedAdded])
                        nonFeaturedAdded++
                    }
                }
            }

            return orderedProjects
        }
    },
    methods: {
        updateFilter(filter=false) {
            this.activeFilter = filter

            if(filter) {
                this.$router.push({ query: { filter }})
            } else {
                this.$router.push({ query: false })
            }
        },

        beforeEnter($el) {
            //console.log('beforeEnter', $el)
            gsap.set($el, {
                opacity: 0,
                yPercent: 25
            })
        },
        enter($el, done) {
            //console.log('enter', $el)
            const delay = .4
            gsap.fromTo($el,
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    duration: .8,
                    opacity: 1,
                    yPercent: 0,
                    ease: 'sine.inOut',
                    delay,
                    overwrite: 1,
                    onComplete: done,
                },
            )
        },
        leave($el, done) {
            // console.log('leave', $el)
            var delay = .2
            gsap.to($el, {
                duration: .4,
                opacity: 0,
                yPercent: 25,
                ease: 'sine.in',
                delay,
                overwrite: 1,
                onComplete: done,
            })
        }
    }
};

</script>

<style lang="scss">

.p-projects {}

.p-projects__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        flex-basis: auto;
        margin-right: auto;
        margin-left: auto;

        .c-card.is-featured {
            grid-column: 1 / 3;
        }
    }

    @media #{md("md")} {
        grid-template-columns: repeat(3, 1fr);

        .c-card.is-featured {
            grid-column: 1 / 4;
        }
    }
}

.p-projects__filters {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem -.5rem;

    .c-btn {
        margin: .25rem .5rem;

        &.is-active {
            pointer-events: none;

            .c-btn__ripple {
                transform: scale(1);
            }
        }

        .js-magnet-hit {
            --hit-x: -.1em;
        }
    }
}


/*=========================================
=            Filter Animations            =
=========================================*/

// Animation
// .anim-project-grid-enter {
//     transition: opacity .4s ease-in, transform .4s $in-quad;
// }

// .anim-project-grid-enter,
// .anim-project-grid-leave-to {
//     transform: translate(0, 2em);
//     opacity: 0;
// }

// .anim-project-grid-leave-active {
//     position: absolute;
//     z-index: -1;
// }

</style>
