/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: 'rounded',
        mode: 'crop',
        width: 500,
        height: 500,
        quality: 100,
        position: 'center-center'
    },
    {
        name: 'square',
        mode: 'crop',
        width: 800,
        height: 800,
        quality: 80,
        position: 'center-center'
    },
    {
        name: 'portrait',
        mode: 'crop',
        width: 800,
        height: 1140,
        quality: 80,
        position: 'center-center'
    },
    {
        name: 'cardProject',
        mode: 'resize',
        width: 800,
        quality: 90,
        srcset: `["300w", "324w", "339w", "360w", "410w", "600w", "648w", "678w", "720w", "810w", "865w", "1730w"]`
    }
]
export const getAsset = (field = 'image', format = false) => {

    let transform

    const data = `
        url
        title
        width
        height
    `

    if ( format && typeof format == 'string' ) {
        transform = transforms.find(t => t.name == format)
    }

    if ( transform ) {

        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `
        } else {

            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                }
            `
        }

    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default {
    getAsset
}
