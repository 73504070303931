<template>
    <div
        v-if="data.headings.length > 0"
        class="b-content-headline"
    >
        <template v-for="(heading, i) in data.headings">
            <spacer
                v-if="i > 0"
            />
            <heading
                :title="heading.headline"
                :tagTitle="heading.headlineTag"
                :classTitle="heading.headlineClass"
                :suptitle="heading.suptitle"
            />
        </template>
    </div>
</template>

<script>
import Spacer from 'objects/Spacer'
import Heading from 'objects/Heading'

export default {
    name: 'BlockContentHeadline',
    components: {
        Heading,
        Spacer
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
};

</script>

<style lang="scss">

.b-content-headline {
}

</style>
