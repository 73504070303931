<template>
    <picture
        v-if="image"
        :class="className"
    >
        <source
            v-if="image.srcset"
            :data-srcset="image.srcset"
            :type="image.mimeType"
        >
        <img
            :src="lazy ? '/static/images/blank.gif' : image.url"
            :data-sizes="lazy ? 'auto' : false"
            :data-src="lazy ? image.url : false"
            :alt="title ? title : image.title"
            class="o-asset__img"
            :width="image.width"
            :height="image.height"
            :class="{ 'lazyload' : lazy }"
        />
    </picture>
</template>

<script>

export default {
    name: 'Asset',
    props: {
        asset: {
            type: Object|Array,
            default: () => []
        },
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        cover: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = 'o-asset'
            classname += this.cover ? ' -cover' : ''
            return classname
        },
        image() {
            let image = this.asset

            // If is array, take first index
            if(Array.isArray(image) && image.length > 0) {
                return image[0]
            }

            if(image && image.url) {
                return image
            }

            if(this.url) {
                return {
                    url: this.url,
                    title: this.title,
                }
            }
        },
    }
};

</script>

<style lang="scss">

.o-asset {
    display: block;

    &.-cover {

        .o-asset__img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }
}

.o-asset__img {
    @include img;
}

</style>
