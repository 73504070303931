<template>
    <script v-if="structure" type="application/ld+json">
        {{ structure }}
    </script>
</template>

<script>

import { defaultLocal, currentLocal } from 'src/i18n'

export default {
    name: 'StructuredDatasJob',
    props: {
        job: {
            type: Object,
            required: true
        },
    },
    computed: {
        structure() {

            const job = this.job

            let json = {
                "@context": "http://schema.org/",
                "@type": "JobPosting",
                "identifier": {
                    "@type": "PropertyValue",
                    "name": "MamboMambo",
                    "value": job.refNumber || job.id
                },
                "title": job.title,
                "description": job.description,
                "datePosted": this.job.datePost,
                "validThrough": this.expirationDate,
                "employmentType": "FULL_TIME",
                "hiringOrganization": {
                    "@type": "Organization",
                    "name": "MamboMambo",
                    "email": "allo(at)mambomambo.ca",
                    "sameAs": "https://mambomambo.ca",
                    "logo": "https://mambomambo.ca/static/logos/mambomambo-symbol.svg"
                },
                "jobLocation": {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",

                        "streetAddress": "276 rue Christophe Colomb Est #100",
                        "addressLocality": "Québec",
                        "addressRegion": "Québec",
                        "postalCode": "G1K 3T2",
                        "addressCountry": "CA"
                    }
                }
            }

            return json
        },
        expirationDate() {

            if ( this.job.expiryDate )
                return this.job.expiryDate

            const publicationDate = new Date(this.job.datePost)
            let dynamicExpiratioNDate = new Date(publicationDate.setDate(publicationDate.getDate() + 30)).toISOString()

            return dynamicExpiratioNDate
        }
    },
    methods: {
        employmentType(schedules) {

            const matches = {
                bfulltime: 'FULL_TIME',
                bparttime: 'PART_TIME',
                bcontract: 'CONTRACTOR',
                boccasional: 'TEMPORARY',
                bpermanent: 'FULL_TIME',
                bseasonal: 'TEMPORARY',
                bpaidinternship: 'INTERN',
                bunpaidinternship: 'INTERN',
                btelecommuting: 'OTHER',
                bstudentemployment: 'OTHER',
            }

            return schedules.map(schedule => {
                return matches[schedule]
            })
        }
    }
}
</script>
