<template>
    <div
        :class="className"
    >
        <loader />

        <app-header @toggleNav="toggleNav" />

        <main
            class="l-main"
            ref="main"
            @mouseenter="mouseEnterMain()"
            @mouseleave="mouseLeaveMain()"
        >
            <router-view :page="currentPage" />
        </main>

        <app-footer
            :showSocialMedias="settings ? settings.showSocialMedias : true"
        />
        <app-cursor />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';
import AppCursor from 'objects/AppCursor';

import Loader from 'objects/Loader';

import { EventBus } from 'src/event-bus'

import { mapState } from 'vuex'

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppFooter,
        AppCursor,
        Loader,
    },
    data: () => ({
        navIsOpen: false,
    }),
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            if(this.settings.darkMode) {
                classname += ' -dark-mode'
            }

            return classname
        },
        settings() {
            const settings = this.currentPage.pageSettings
            return settings && settings.length > 0 ? settings[0] : false
        }
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen
        },
        mouseEnterMain() {
            if(this.settings.darkMode) {
                EventBus.$emit('cursor-hover-in', {
                    type: 'light',
                    data: {}
                })
            }
        },
        mouseLeaveMain() {
            if(this.settings.darkMode) {
                EventBus.$emit('cursor-hover-out')
            }
        },
    },
}

</script>

<style lang="scss">

.l-wrapper {

    &.-dark-mode {
        --color-text: #{$color-light};
        --color-bg: #{$color-dark};

        .l-main {
            background-color: var(--color-bg);
        }
    }
}

.l-main {
    padding-top: calc(#{$header-height} + 2em);

    @media #{md("sm")} {
        padding-top: calc(#{$header-height--desktop} + 4em);
    }
}

</style>
