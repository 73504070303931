<template>
    <span :class="className">
        <span
            ref="inner"
            class="o-cursor__inner"
            :style="color"
        >
            <span
                ref="content"
                class="o-cursor__content"
            >
                <span
                    v-if="label"
                    ref="label"
                    class="o-cursor__label | t-sub"
                >
                    {{ label }}
                </span>
            </span>
        </span>
    </span>
</template>


<script>

import { gsap } from 'gsap/all';
import { EventBus } from 'src/event-bus'

import { isTouch } from 'src/utils'

export default {
    name: 'AppCursor',
    data: () => ({
        // isActive: false,
        state: [],
        // isScrolling: false,
        // scrollYPerc: 0,
    }),
    mounted() {

        this.docElement = document.documentElement

        if (isTouch) {
            return
        }

        // Show cursor
        document.body.classList.add('no-cursor');

        // Define vars
        this.$hoverable = document.querySelectorAll('a');
        this.content = false;
        this.rotation = 0;

        this.show()

        // Document events
        document.addEventListener('mousemove', e => {
            this.move(e.clientX, e.clientY)
        })

        // // Window events
        // window.addEventListener('mousedown', () => {
        //     this.isActive = true
        // })

        // window.addEventListener('mouseup', () => {
        //     this.isActive = false
        // })

        // window.addEventListener('scroll', this.scroll)

        // window.addEventListener('scrollstart', () => {
        //     this.isScrolling = true
        //     EventBus.$emit('cursor-reset')
        // })

        // window.addEventListener('scrollend', () => {
        //     this.isScrolling = false
        // })

        // Hover
        EventBus.$on('cursor-hover-in', params => {

            this.state.push({
                type: params.type ? params.type : false,
                label: params.data && params.data.label ? params.data.label : false,
                color: params.data && params.data.color ? params.data.color : false,
            })
        })

        EventBus.$on('cursor-hover-out', () => {
            this.state.pop()
        })

        // Set active
        EventBus.$on('cursor-set-active', activate => {
            this.isActive = activate
        })

        // Reset
        EventBus.$on('cursor-reset', () => {
            this.isActive = false

            this.state = []
        })

        // Update position
        EventBus.$on('cursor-update-position', ({x, y}) => {
            this.move(x, y)
        })
    },
    computed: {
        stateLength() {
            return this.state.length
        },
        type() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].type : false
        },
        label() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].label : false
        },
        rotate() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].rotate : false
        },
        color() {
            const color = this.stateLength > 0 ? this.state[this.stateLength - 1].color : false
            return color ? `--cursor-bg: ${color};` : false
        },
        className() {
            let className = 'o-cursor'

            if (this.type) {
                className += ` -${this.type}`
            }
            if (this.label) {
                className += ' has-content'
            }
            if (this.isActive) {
                className += ' is-active'
            }
            if (this.showScroll) {
                className += ' -hide'
            }

            return className
        },
        // clipPath() {
        //     const progress = this.scrollYPerc

        //     let polygon = [
        //         [50, 50],
        //         [100, 0],
        //         [100, Math.min(4 * progress, 100)],
        //     ]

        //     let x, y
        //     if (progress >= 25) {
        //         x = Math.max((-4 * progress + 200), 0)
        //         y = 100
        //         polygon.push([x, y])
        //     }

        //     if (progress >= 50) {
        //         x = 0
        //         y = Math.max((-4 * progress + 300), 0)
        //         polygon.push([x, y])
        //     }

        //     if (progress >= 75) {
        //         x = Math.min((4 * progress - 300), 100)
        //         y = 0
        //         polygon.push([x, y])
        //     }

        //     polygon = polygon.map(p => p.join('% ').concat('%')).join(', ');

        //     return `--clip-path: polygon(${polygon});`
        // },
        // showScroll() {
        //     return this.isScrolling && this.state.length === 0
        // },
    },
    methods: {
        show() {
            // console.log(`${this.constructor.name}:show`)

            gsap.to(this.$el, {
                duration: .2,
                scale: 1,
                opacity: 1,
                onComplete: () => {
                    gsap.set(this.$el, { clearProps: 'all' })
                }
            })
            // this.docElement.addEventListener('mousemove', this.move)
        },

        hide() {
            // console.log(`${this.constructor.name}:hide`)

            gsap.to(this.$el, {
                duration: .2,
                scale: 0,
                opacity: 0
            })
            // this.docElement.removeEventListener('mousemove', this.move)
        },

        move(x, y) {
            gsap.to(this.$el, {
                duration: .6,
                x,
                y,
                ease: 'expo.out',
            })
        },
        // scroll() {
        //     this.scrollYPerc = Math.round(scrollY / (this.docElement.scrollHeight - this.W.h) * 100)
        // }
    },
}

</script>

<style lang="scss">

.no-cursor * {
    cursor: none !important;
}


/*==============================
=            Cursor            =
==============================*/

.o-cursor {
    z-index: 1000;
    position: fixed;
    top: -2.5rem;
    left: -2.5rem;
    pointer-events: none;
    // backface-visibility: hidden;
    display: none;


    .no-cursor & {
        display: block;
        will-change: transform;
    }

    &.has-content {

        .o-cursor__inner {
            transform: scale(1);
            transition: all .3s $in-out-quad;

            &:before {
                transform: scale(1);
                transition: all .2s $out-quad .1s;
                transition: all .3s $out-quad 0s;
            }
        }

        .o-cursor__label {
            transform: rotate(0) skew(0);
            transition: all .3s $out-sine .1s;
        }
    }

    &.-light {

        .o-cursor__inner {
            --cursor-bg: #{$color-light};
        }
    }

    &.-hover {

        .o-cursor__inner {
            --cursor-bg: radial-gradient(#{$color-primary}, rgba(255, 255, 255, 0));

            opacity: .5;
            transform: scale(.5);
            transition: all .2s $in-out-quad;
        }
    }

    &.-hidden {

        .o-cursor__inner {
            transform: scale(0);
            transition: transform .2s $out-quad, background-color .1s $out-quad;
        }
    }


    &.-rotate {

        .o-cursor__content {
            animation: anim-cursor-rotate 4s linear infinite;
        }
    }

    @media (prefers-reduced-motion: reduce) {

        .o-cursor__inner {
            transition: none;
        }

        &.-rotate .o-cursor__content {
            animation: none;
        }
    }
}

.o-cursor__inner {
    --cursor-bg: #{$color-dark};

    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--cursor-bg);
    border-radius: 50%;
    box-shadow: $box-shadow;
    transform: scale(0.12);
    transition: all .2s $in-out-quad;
    will-change: transform;

    &:before {
        @include pseudo-el($width: auto, $height: auto, $bg: $color-light);
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: inherit;
        transform: scale(0);
    }
}

.o-cursor__content {
    display: block;
}

.o-cursor__label {
    display: block;
    width: 100%;
    margin: auto;
    padding-right: 1em;
    padding-left: 1em;
    text-align: center;
    wprd-break: break-all;
    transform: rotate(10deg) skew(10deg);
}


/*----------  Rotation  ----------*/

@keyframes anim-cursor-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(1turn);
    }
}


</style>
