<template>
    <builder :data="page.layoutResult" />
</template>

<script>

import Builder from 'builder/Builder';

export default {
    name: 'WorkResults',
    components: {
        Builder,
    },
    props: {
        page: Object,
    },
};

</script>

<style lang="scss">

</style>
