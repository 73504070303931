import { fetchApi, fetchJSON } from 'src/graphql/config';
import queries from 'src/graphql/queries';
import { linkResolver } from 'src/router';

/*
** Global
*/

const initialData = window.__initialData__

// State
const state = {
    currentSite: initialData.currentSite,
    sites: initialData.sites,
    nav: [],
    footer: null,
    instagram: {
        account: {},
        story: null,
        medias: []
    },
    contact: null,
    currentPage: {},
    currentPageName: null,
};

// Getters
const getters = {
    otherSites: state => state.sites.filter(s => s.id !== state.currentSite.id),
    getCurrentPageID: state => state.currentPage.id ? state.currentPage.id : false
};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {
        return Promise.all([
            store.dispatch('loadParameters'),
            store.dispatch('loadInstagramDatas')
        ]);
    },
    loadParameters(store) {
        fetchApi(queries.general)
            .then(data => {

                // PARAMETERS
                //

                // Destructure object to vars
                const { menu, instagramStory, placeholder, ...footer} = data.parameters

                // HEADER
                menu.forEach(item => {
                    item.entry = item.entry[0];
                    item.path = linkResolver(item.entry.sectionHandle);
                });

                store.commit('setNav', menu);

                // FOOTER
                const { footerTitles, image, socialMedias } = data
                store.commit('setFooter', footer);

                // INSTAGRAM STORY
                if ( instagramStory && instagramStory[0] )
                    store.commit('setInstagramStory', instagramStory[0]);

                // PLACEHOLDER ???

                // CONTACT
                //
                let contact = data.contact.contactInfos
                contact = contact.length > 0 ? contact[0] : null
                store.commit('setContact', contact);
            });
    },
    loadInstagramDatas(store) {
        fetchJSON('/data/instagram.json')
            .then(data => {

                // INSTAGRAM ACCOUNT
                if ( data.account )
                    store.commit('setInstagramAccount', data.account);

                // INSTAGRAM IMAGES
                if ( data.medias )
                    store.commit('setInstagramMedias', data.medias);

            })
    },
    loadContent(store, to) {

        return new Promise((resolve, reject) => {

            // Timer in seconds
            const timer = new Date

            // Start loader
            store.dispatch('loader/startLoad', null, {root: true});

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const singleEntry = to.meta.single;

            // Empty promise if nothing to load (static page)
            if(typeof handle === 'undefined') {
                fetchContent = new Promise(r => r({}))
            }
            // For single entries, load page with handle
            else if (singleEntry) {
                fetchContent = store.dispatch('pages/loadSingle', handle, {root: true});
            // For channel/structure, load page with handle and slug
            } else {
                const slug = to.params.slug;
                fetchContent = store.dispatch('pages/loadEntry', { handle, slug }, {root: true});
            }

            fetchContent.then((page) => {
                // Duration in ms of the fetchContent + 800ms for the loader cover delay (see loader component) + 10ms for latency
                let delay = (timer - new Date) + 800
                delay = delay < 0 ? 0 : delay

                setTimeout(() => {
                    store.commit('setCurrentPage', {});
                    store.commit('setCurrentPageName', to.name)
                    resolve(page);

                    // Timeout to let template to render data
                    setTimeout(() => {
                        // Set page after router page has updated to avoid js errors
                        store.commit('setCurrentPage', page);

                        // Differ endLoad to allow builder loads to start
                        setTimeout(() => {
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 10);
                    }, 10);

                }, delay);
            })
            .catch(e => {

                reject(e)
                store.dispatch('loader/endLoad', null, {root: true})

            })

        });
    },
    fetchPageUrl(store, { pageID, siteID }) {

        // Start loader
        store.dispatch('loader/startLoad', null, {root: true});

        return fetchApi(queries.pageURL(pageID, siteID))
            .then(data => {
                // If entry exists, return site entry url
                if(data && data.entry) {
                    return data.entry.url
                // Else return site homepage
                } else {
                    const baseUrl = store.state.sites.find(s => s.id === siteID).baseUrl
                    return `${window.location.origin}${baseUrl}`
                }

                store.dispatch('loader/endLoad', null, {root: true})
            })
    },
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav;
    },
    setFooter(state, data) {
        state.footer = data;
    },
    setInstagramAccount(state, data) {
        state.instagram.account = data;
    },
    setInstagramStory(state, data) {
        state.instagram.story = data;
    },
    setInstagramMedias(state, data) {
        state.instagram.medias = data;
    },
    setContact(state, data) {
        state.contact = data;
    },
    setCurrentPage(state, page) {
        state.currentPage = page;
    },
    setCurrentPageName(state, name) {
        state.currentPageName = name
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
