<template>
    <router-link
        class="c-card -project"
        :to="`/${uri}`"
        :title="title"
        :class="{ 'is-featured' : featured }"
        v-cursor.rotate="{ label: hoverText ? hoverText : $t('cursor.see') }"
        :data-featured="featured"
        v-magnet
    >
        <div
            class="c-card__asset || js-magnet-inner"
            :class="[
                { '-single' : featured && !asset },
                { '-cover' : !featured && !asset }
            ]"
            :data-magnet-scale="featured ? 1.1 : false"
        >
            <span
                class="c-card__bg"
                :style="color ? `background-color: ${color};` : false"
            >
                <asset
                    :asset="bgAsset"
                    :lazy="true"
                />
            </span>
            <asset
                v-if="asset || (featured && bgAsset)"
                :asset="asset ? asset : bgAsset"
                :lazy="true"
                class="c-card__preview"
            />
        </div>

        <div class="c-card__inner">

            <div
                v-if="children.length > 0"
                class="c-card__list | t-t5"
            >
                <list
                    :items="subProjects"
                    :links="false"
                />
            </div>

            <div
                class="c-card__content"
                :class="[
                    { '-light' : hoverLight },
                    { 'js-magnet-inner' : !featured }
                ]"
                :data-magnet-scale="!featured ? 1 : false"
            >
                <h3
                    v-if="catchphrase"
                    class="c-card__title | t-t3"
                >
                    {{ catchphrase }}
                </h3>

                <h4
                    v-if="title"
                    class="c-card__sub"
                >
                    {{ title }}
                </h4>
            </div>
        </div>
        <span class="js-magnet-hit"></span>
    </router-link>
</template>

<script>

import Asset from 'objects/Asset'
import List from 'components/List'

export default {
    name: 'CardProject',
    components: {
        Asset,
        List,
    },
    props: {
        featured: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null
        },
        catchphrase: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: null
        },
        uri: {
            type: String,
            default: ''
        },
        image: {
            type: Array,
            default: null
        },
        backgroundImage: {
            type: Array,
            default: null
        },
        hoverText: {
            type: String,
            default: null
        },
        hoverLight: {
            type: Boolean,
            default: false,
        },
        children: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        asset() {
            let asset = this.image
            asset = asset && asset.length > 0 ? asset[0] : false

            return asset
        },
        bgAsset() {
            let asset = this.backgroundImage
            asset = asset && asset.length > 0 ? asset[0] : false

            return asset
        },
        subProjects() {
            return this.children.filter(c => c.typeHandle !== 'results')
        },
    },
};

</script>

<style lang="scss">


.c-card.-project {

    .c-card__asset {
        z-index: 2;
        clip-path: none;
        background-color: $color-light;
        border-radius: 0;
        box-shadow: $box-shadow;

        &.-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .c-card__bg {
                clip-path: circle(25%);
            }
        }
    }

    img {
        @include img;
        transform: scale(.9);
        transition: transform .4s ease-out;
    }

    .c-card__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: circle(25%);
        transition: clip-path .4s ease-out;
    }

    .c-card__inner {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 1.5em;
    }

    .c-card__list {
        z-index: 1;
    }

    .c-card__content {
        z-index: 3;
        padding-bottom: 0;

        &.-light {
            transition: color .2s ease-in;
        }
    }

    @at-root a#{&} {

        &:hover {

            .c-card__bg {
                clip-path: circle(100%);
                transition: clip-path .6s ease-out;
            }

            img {
                transform: scale(1);
                transition: transform .3s ease-out 0s;
            }

            .c-card__content.-light {
                color: $color-light;
                transition: color .2s ease-out;
            }
        }
    }

    @media #{md("xs")} {

        &.is-featured {
            z-index: 3;
            background-color: $color-light;
            box-shadow: $box-shadow;
            margin-top: 10%;
            margin-bottom: 10%;

            .c-card__asset {
                width: calc(1/3 * 100%);
                margin-left: 18%;
                background-color: transparent;
                box-shadow: none;
                overflow: visible;
                pointer-events: none;

                .c-card__bg {
                    position: relative;
                    opacity: 0;
                    visibility: hidden;
                }

                .c-card__preview {
                    position: absolute;
                    top: 50%;
                    left: calc((100 - 220)/2 * 1%);
                    width: 220%;
                    transform: translate(0, -50%);
                }

                &.-single {

                    .c-card__preview {
                        position: relative;
                        top: auto;
                        left: auto;
                        width: 100%;
                        opacity: 0;
                        visibility: hidden;
                        transform: none;
                    }
                    .c-card__bg {
                        position: absolute;
                        top: -10%;
                        left: calc((100 - 170)/2 * 1%);
                        width: 170%;
                        height: 0;
                        padding-top: 170%;
                        opacity: 1;
                        visibility: visible;
                        clip-path: circle(50%);

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) scale(1);
                            transition: transform .3s ease-out 0s;
                        }
                    }
                }
            }

            .c-card__inner {
                right: 0;
                left: auto;
                display: flex;
                justify-content: flex-end;
                height: 100%;
                margin-left: auto;
                width: calc(1/3 * 100%);
            }

            .c-card__list {
                display: none;
            }

            img {
                transform: none;
            }

            @at-root a#{&} {

                &:hover {

                    .c-card__asset.-single {

                        .c-card__bg {
                            clip-path: circle(50%);
                        }

                        img {
                            transform: translate(-50%, -50%) scale(1.05);
                        }
                    }
                }
            }
        }
    }

    @media #{md("sm")} {

        &.is-featured {

            .c-card__inner {
                justify-content: space-between;
            }

            .c-card__list {
                display: block;
            }
        }
    }
}


</style>
