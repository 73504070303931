<template>
    <builder :data="page.layoutPage" />
</template>

<script>

import Builder from 'builder/Builder';

export default {
    name: 'Culture',
    components: {
        Builder,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seoPage ? this.page.seoPage.title : null
        }
    },
};

</script>

<style lang="scss">

.p-culture {}

</style>
