<template>
    <builder :data="page.layoutPage" />
</template>

<script>

import Builder from 'builder/Builder';

export default {
    name: 'WorkSubProject',
    components: {
        Builder,
    },
    props: {
        page: Object,
    },
};

</script>

<style lang="scss">


</style>
