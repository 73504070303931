<template>
    <div :class="className">
        <div
            v-if="counter"
            class="c-slider__counter"
        >
            <span class="c-slider__index">{{ currentIndex }}</span> / {{ totalSlides }}
        </div>

        <flickity
            ref="flkty"
            :options="flktyOpts"
            class="c-slider__inner"
        >
            <slot></slot>
        </flickity>
    </div>
</template>

<script>

import Flickity from 'vue-flickity'

export default {
    name: 'Slider',
    components: {
        Flickity
    },
    data: () => ({
        isDragging: false,
        currentIndex: 0,
    }),
    props: {
        modifier: {
            type: String,
            default: 'default',
        },
        counter: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.$el = this
        const flkty = this.$refs.flkty

        flkty.on('dragStart', this.onDragStart = () => this.isDragging = true );
        flkty.on('dragEnd', this.onDragEnd = () => this.isDragging = false );

        if(this.counter > 0) {
            flkty.on('select', this.onSelect = i => this.currentIndex = i)
        }

        this.$images = flkty.$el.querySelectorAll('img') || [];

        this.$images.forEach($img => $img.addEventListener('load', this.resize))
    },
    computed: {
        className() {
            let classname = 'c-slider'

            if(this.modifier) {
                classname += ` -${this.modifier}`
            }

            return classname
        },
        flktyOpts() {
            let options

            if(this.modifier === 'instagram') {
                options = {
                    wrapAround: true,
                    prevNextButtons: false,
                    cellAlign: 'left'
                }
            } else if(this.modifier === 'colors') {
                options = {
                    cellAlign: 'left',
                    pageDots: false,
                    contain: true,
                    freeScroll: true,
                    groupCells: true
                }
            } else if(this.modifier === 'filters') {
                options = {
                    cellAlign: 'left',
                    pageDots: false,
                    freeScroll: true,
                }
            } else if(this.modifier === 'projects') {
                options = {
                    cellAlign: 'left',
                    pageDots: false,
                    contain: true,
                    freeScroll: true,
                    prevNextButtons: false,
                    watchCSS: true,
                }
            } else {
                options = {
                    cellAlign: 'left',
                    pageDots: false,
                    contain: true
                }
            }

            return options
        },
        totalSlides() {
            return this.$refs.flkty.slides.length
        },
    },
    methods: {
        resize() {
            this.$refs.flkty.resize()
        },
    },
    beforeDestroy() {

        this.$images.forEach($img => $img.removeEventListener('load', this.resize))

        this.$refs.flkty.off('dragStart', this.onDragStart);
        this.$refs.flkty.off('dragEnd', this.onDragEnd);

        if(this.counter > 0) {
            flkty.off('select', this.onSelect)
        }
    }
};

</script>

<style lang="scss">


.c-slider {
    --slide-width: 100%;

    &.-default {

        .flickity-viewport {
            overflow: hidden;
        }

        img {
            @include img;
        }
    }

    &.-filters {
        margin-top: 2em;
        margin-bottom: 2em;

        .flickity-viewport {
            overflow-x: hidden;
        }
    }

    &.is-dragging {

        .c-slider__slide {
            pointer-events: none;
        }
    }

    // Flickity override
    .flickity-prev-next-button {
        position: absolute;
        top: calc(50% - 2.25em/2);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.25em;
        height: 2.25em;
        background-color: $color-light;
        border-radius: 50%;
        box-shadow: $box-shadow-2;

        &.previous {
            left: calc(-2.25em/2);
        }

        &.next {
            right: calc(-2.25em/2);
        }

        &[disabled] {
            opacity: 0;
            pointer-events: none;
        }

        .flickity-button-icon {
            position: relative;
            top: auto;
            left: auto;
            width: .6em;
            height: .6em;
            margin: auto;

        }
    }

    @media #{md("sm")} {
        --slide-width: calc(1/3 * 100%);
    }

    @media #{md("md")} {
        --slide-width: calc(1/4 * 100%);
    }

    @media #{md("xl")} {
        --slide-width: calc(1/5 * 100%);
    }
}


.c-slider__slide {
    width: var(--slide-width);
}


/*==============================
=            Colors            =
==============================*/

.c-slider.-colors {
    --width: 90%;
    --padding: 1em;
    width: 100%;
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);

    .c-slider__slide {
        width: calc(var(--width) - var(--padding));

        &:nth-child(n+2) {
            margin-left: var(--padding);
        }
    }

    // Flickity override
    .flickity-prev-next-button {
        top: calc(50% - 2.25em/2 - .75em);
    }

    @media #{md("xs")} {
        --width: 40%;
    }

    @media #{md("sm")} {
        --width: 30%;
    }

    @media #{md("md")} {
        --width: calc(25% + var(--padding)/4);
    }

    @media #{md("lg")} {
        //--width: calc(1/5 * 100%);
    }

    @media not all and (min-width: $grid-max-width) {
        left: calc(50% - 50vw);
        width: 100vw;
        overflow: hidden;

        .flickity-prev-next-button {

            &.previous {
                margin-left: .5em;
            }

            &.next {
                margin-right: .5em;
            }
        }
    }

    @media (min-width: $grid-max-width) {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .flickity-viewport {
            overflow: hidden;
        }
    }
}


/*================================
=            Projects            =
================================*/


.c-slider.-projects {
    --slide-width: 70%;

    .c-slider__inner {
        grid-gap: var(--grid-gutter);
    }

    @media #{md("xs")} {
        --slide-width: 40%;
    }

    @media #{md("sm")} {
        --slide-width: calc(1/3 * 100% - 2em/3);
    }

    @media #{md("md", "max")} {
        @include center-viewport;
        padding-right: var(--grid-gutter);
        padding-left: var(--grid-gutter);
        overflow: hidden;

        .c-slider__inner {

            &:after {
                content: "flickity";
                display: none;
            }
        }

        .c-slider__slide:not(:first-child) {
            margin-left: var(--grid-gutter);
        }

        .flickity-viewport {
            overflow: initial;
        }
    }

    @media #{md("md")} {
        --slide-width: 100%;

        .c-slider__inner {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}


</style>
