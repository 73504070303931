export default {
    btn: {
        hireUs: 'Hire us',
        callUs: 'Call us',
        send: 'Send',
        all: 'All',
    },
    cursor: {
        dringDring: 'Dring Dring',
        workWithUs: 'Hire us',
        getDirection: 'Get direction',
        see: 'Check this out !',
    },
    header: {
        menu: 'Menu',
        close: 'Close',
    },
    footer: {
        updateLanguage: 'Update website language',
        getHere: 'Get here',
    },
    misc: {
        email: 'Email',
        phone: 'Phone',
        year: 'Year',
        industry: 'Industry',
        whatWeDid: 'What we did',
        results: 'Results',
        tools: 'Tools',
        project: 'project',
        otherTools: 'Shoutout to a couple of other tools !',
        ourProjects: 'Our projects',
        ourCulture: 'Our culture',
        relatedProjectsTitle: 'Other projects you might like.',
        otherProjects: 'Other Projects',
        awardsTitle: 'Awards and recognition',
    },
    mandate: {
        success: {
            title: 'The email was',
            sent: 'sent',
            desc: 'While we review it, keep browsing!',
        },

        form: {
            fullname: 'Full name',
            organization: 'Organization',
            phone: 'Phone number',
            email: 'Email address',
            projectName: 'Project name',
            projectDesc: 'Project description',
            pdfFile: 'Choose a PDF file',
            budget: 'Budget',
            budget5K: '5K to 10K',
            budget10K: '10K to 20K',
            budget20K: '20K to 30K',
            budget30K: '30K to 50K',
            budget50K: '50K and more',
            deliveryDate: 'Expected time of delivery',
            deliveryDate3m: 'In 3 months',
            deliveryDate6m: 'In 6 months',
            deliveryDate1y: 'In a year',
            deliveryDateMore: 'In more than a year',
            deliveryDateUndefined: 'To be determined',
        },
    },
}
