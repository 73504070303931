<template>
    <anim-text
        :text="data.body"
        :cms="true"
        class="b-content-text"
    />
</template>

<script>


import AnimText from 'objects/AnimText'

export default {
    name: 'BlockContentText',
    components: {
        AnimText,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
};

</script>

<style lang="scss">


</style>
