<template>
    <div id="results" class="b-results | w-full">
        <template v-if="data.externalLink">
            <a
                :href="data.externalLink"
                target="_blank"
                rel="noopener"
            >
                <div
                    v-if="asset"
                    class="b-results__image"
                >
                    <asset :asset="asset" />
                </div>
                <div class="b-results__content">
                    <div
                        v-if="data.subtitle"
                        class="b-results__sub | t-sub"
                        :style="`color:${data.selectColor}`"
                    >
                        {{ data.subtitle }}
                    </div>
                    <div
                        v-if="data.heading"
                        class="t-t3"
                        :style="`color:${data.selectColor}`"
                    >
                        {{ data.heading }}
                    </div>
                </div>
            </a>
        </template>
        <template v-else>
            <div
                v-if="asset"
                class="card-image"
            >
                <asset :asset="asset" />
            </div>
            <div class="b-results__content">
                <div
                    v-if="data.subtitle"
                    class="b-results__sub | t-sub"
                    :style="`color:${data.selectColor}`"
                >
                    {{ data.subtitle }}
                </div>
                <div
                    v-if="data.heading"
                    class="t-t3"
                    :style="`color:${data.selectColor}`"
                >
                    {{ data.heading }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import Asset from 'objects/Asset'

export default {
    name: 'BlockResult',
    components: {
        Asset,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        asset() {
            const asset = this.data.imagePortrait
            return asset.length > 0 ? asset[0] : false
        },
    }
};

</script>

<style lang="scss">

.b-results {
}

.b-results__link {
    display: block;
    width: 100%;
    height: 100%;
}

.b-results__image {
    height: 100%;
    overflow: hidden;
}

.b-results__content {
    padding: 1.5rem;
    position: absolute;
    z-index: 3;
    bottom: 0;
    padding-right: 20%;
    transition: 150ms ease-in-out;
}

.b-results__sub {
    margin-bottom: 1rem;
}

</style>
