<template>
    <div :class="className">
        <component
            v-for="(component, i) in data.children"
            :key="`block-row-children-${i}`"
            :is="component.typeHandle"
            :data="component"
        />
    </div>
</template>

<script>

import BlockContent from 'builder/BlockContent'
import BlockColorSwatches from 'builder/BlockColorSwatches'
import BlockImageGallery from 'builder/BlockImageGallery'
import BlockMediasGallery from 'builder/BlockMediasGallery'
import BlockImageOversized from 'builder/BlockImageOversized'
import BlockImagesSlider from 'builder/BlockImagesSlider'
import BlockTeam from 'builder/BlockTeam'
import BlockProjects from 'builder/BlockProjects'
import BlockUpcomingProjects from 'builder/BlockUpcomingProjects'
import BlockInstagram from 'builder/BlockInstagram'
import BlockTools from 'builder/BlockTools'
import BlockWebsite from 'builder/BlockWebsite'
import BlockSpacer from 'builder/BlockSpacer'


export default {
    name: 'BlockRow',
    components: {
        BlockContent,
        BlockColorSwatches,
        BlockImageGallery,
        BlockMediasGallery,
        BlockImageOversized,
        BlockImagesSlider,
        BlockTeam,
        BlockProjects,
        BlockUpcomingProjects,
        BlockInstagram,
        BlockTools,
        BlockWebsite,
        BlockSpacer,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'grid__row'

            if(this.data.alignX) {
                classname += ` ${this.data.alignX}`
            }

            if(this.data.alignY) {
                classname += ` ${this.data.alignY}`
            }

            if(this.data.responsiveGrid) {
                classname += ' -responsive'
            }

            return classname
        }
    }
};

</script>

<style lang="scss">


</style>
