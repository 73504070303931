<template>
    <div
        v-if="data.video.length > 0"
        class="b-content-video"
    >
        <gallery :items="data.video">
            <template #item="item">
                <vimeo
                    :id="item.video_id"
                    :background="item.backgroundMode"
                    :loop="item.isLoop"
                    :controls="item.hasControl"
                    :playInView="item.playInView"
                />
            </template>
        </gallery>
    </div>
</template>

<script>

import Vimeo from 'objects/Vimeo'
import Gallery from 'components/Gallery'

export default {
    name: 'BlockContentVideo',
    components: {
        Vimeo,
        Gallery,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
};

</script>

<style lang="scss">

.b-content-video {}

</style>
