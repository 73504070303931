<template>
    <div
        v-if="colors"
        class="b-colors | grid__col--12"
    >
        <slider modifier="colors">
            <div
                v-for="color in colors"
                class="c-slider__slide"
            >
                <div class="o-color-swatch">
                    <span
                        class="o-color-swatch__box"
                        :class="{ '-light' : color.colorLabel === 'Blanc' }"
                        :style="`background-color: ${ color.colorCode }`"
                    ></span>
                    <span
                        v-if="color.colorLabel"
                        class="o-color-swatch__label"
                    >
                        {{ color.colorLabel }}
                    </span>
                </div>
            </div>
        </slider>
    </div>
</template>

<script>

import Slider from 'components/Slider'

import { mapState } from 'vuex'

export default {
    name: 'BlockColorSwatches',
    components: {
        Slider,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage
        }),
        colors() {
            const colors = this.data.siteColors
            const siteColors = this.currentPage.siteColors

            if(colors.length > 0) {
                return colors
            }

            if(typeof siteColors !== 'undefined' && siteColors.length > 0) {
                return siteColors
            }

            return false
        },
    },
};

</script>

<style lang="scss">


/*====================================
=            Color swatch            =
====================================*/


.o-color-swatch {
    text-align-last: auto;
}

.o-color-swatch__box {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;

    &.-light {
        box-shadow: $box-shadow;
    }
}

.o-color-swatch__label {
    display: block;
    margin-top: 1em;
}

</style>
