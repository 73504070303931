<template>
    <div :class="className">
        <div
            class="c-accordion__header"
            @click="isOpen ? close() : open()"
        >
            <slot name="title">
                <h3
                    v-if="title"
                    class="c-accordion__title"
                >
                    {{ title }}
                </h3>
            </slot>
        </div>
        <div
            ref="container"
            class="c-accordion__container"
        >
            <div ref="inner" class="c-accordion__content">
                <slot>
                    <div
                        class="t-cms"
                        v-html="content"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

import { gsap, ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Accordion',
    data: () => ({
        isOpen: false,
    }),
    props: {
        title: {
            type: String,
            default: null,
        },
        triggerLabel: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        upsideDown: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = 'c-accordion'

            if(this.isOpen) {
                classname += ' is-open'
            }

            if(this.upsideDown) {
                classname += ` -upside-down`
            }

            return classname
        }
    },
    methods: {
        open() {
            this.isOpen = true;

            const height = this.$refs.inner.offsetHeight;

            gsap.to(this.$refs.container, {
                duration: 1,
                height,
                opacity: 1,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 'auto'
                    });
                },
                ease: 'expo.out',
            });

            const rect = this.$refs.container.getBoundingClientRect()
            const scrollY = rect.top + window.pageYOffset + height/2 - window.innerHeight/2;

            this.animScroll(scrollY);
        },
        close() {
            this.isOpen = false;

            gsap.to(this.$refs.container, {
                duration: .6,
                height: 0,
                opacity: 0,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 0
                    });
                },
                ease: 'expo.out',
            });
        },
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        },
        animScroll(y) {
            gsap.to(window, {
                duration: .4,
                scrollTo: {
                    y: y,
                    autoKill: false,
                },
                ease: 'expo.out',
            });
        },
    },
    // watch: {
    //     isOpen(open) {
    //         if(open) {
    //             this.open()
    //         } else {
    //             this.close()
    //         }
    //     }
    // },
};
</script>

<style lang="scss">

.c-accordion {

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
        }
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }
    }
}


.c-accordion__header {
    // padding-top: 1em;
    // padding-bottom: 1em;
    user-select: none;
    cursor: pointer;

    &:hover,
    .is-open & {
    }

    &:hover {
    }

    &:hover,
    .is-open &:hover {
    }

    .is-open & {
    }
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;
}


.c-accordion__content {
    // max-height: 400px;
    padding-bottom: 1em;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

</style>
