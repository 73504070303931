<template>
    <div class="o-heading">
        <anim-text
            v-if="suptitle"
            :tag="tagSuptitle"
            :text="suptitle"
            class="o-heading__sup | t-sub"
        />
        <anim-text
            v-if="title"
            :tag="tagTitle"
            :text="title"
            class="o-heading__title"
            :class="classTitleFix"
        />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'

export default {
    name: 'Heading',
    components: {
        AnimText,
    },
    props: {
        tagSuptitle: {
            type: String,
            default: 'h2',
        },
        tagTitle: {
            type: String,
            default: 'h3',
        },
        classTitle: {
            type: String,
            default: 't-t2',
        },
        suptitle: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        classTitleFix() {
            let classTitleFix = this.classTitle

            if(classTitleFix === 'large') {
                classTitleFix = 't-t2'
            } else if(classTitleFix === 'x-large') {
                classTitleFix = 't-t1'
            }

            return classTitleFix
        },
    },
};

</script>

<style lang="scss">

.o-heading__title {

    em {
        @extend .t-special;
        margin-right: .2em;
    }

    &:nth-child(n+2) {
        margin-top: 1.25rem;
    }
}


</style>
