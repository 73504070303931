<template>
    <div
        v-if="hasProjects"
        :class="className"
    >
        <card-project
            v-if="featured"
            :featured="true"
            :title="featured.title"
            :catchphrase="featured.catchphrase"
            :color="featured.color"
            :uri="featured.uri"
            :image="featured.image"
            :backgroundImage="featured.backgroundImage"
            :hoverText="featured.hoverText"
            :hoverLight="featured.hoverLight"
            :children="featured.children"
        />

        <slider modifier="projects">
            <div
                v-for="(project, i) in list"
                :key="`block-project-${_uid}-${i}`"
                class="c-slider__slide"
            >
                <card-project
                    :featured="false"
                    :title="project.title"
                    :catchphrase="project.catchphrase"
                    :color="project.color"
                    :uri="project.uri"
                    :image="project.image"
                    :backgroundImage="project.backgroundImage"
                    :hoverText="project.hoverText"
                    :hoverLight="project.hoverLight"
                    :children="project.children"
                />
            </div>
        </slider>

    </div>
</template>

<script>

import CardProject from 'components/CardProject'
import Slider from 'components/Slider'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockProjects',
    components: {
        CardProject,
        Slider,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        projects: [],
    }),
    created() {
        let projectIds = this.data.selectEntriesWork

        // Return if empty
        if(projectIds.length === 0) {
            return
        }

        projectIds = projectIds.map(p => p.id)

        this.$store.dispatch('works/preloadMultiple', projectIds)
            .then(projects => {
                this.projects = projects
            })
    },
    computed: {
        className() {
            let classname = 'b-projects'

            //console.log(this.data.blockSettings[0])

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
        totalProjects() {
            return this.projects.length
        },
        hasProjects() {
            return this.totalProjects > 0
        },
        featured() {
            return this.hasProjects ? this.projects[0] : false
        },
        list() {
            if(this.totalProjects < 2) {
                return false
            }

            const projects = [...this.projects]
            projects.map(p => p.featured === false)

            return projects.splice(1)
        },
    },
};

</script>

<style lang="scss">

.b-projects {

    @media #{md("xs", "max")} {

        .c-slider {
            margin-top: 2em;
        }
    }
}

</style>
