<template>
    <div>
        <section class="l-section -header">
            <span
                v-if="headerImage"
                class="p-work__cover"
                :style="`--image: url(${ headerImageMobile ? headerImageMobile : headerImage });`"
            >
                <asset
                    :url="headerImage"
                    :title="page.title"
                    :lazy="false"
                />
            </span>

            <div class="l-section__inner">
                <div class="grid">
                    <div class="grid__row u-jc-start u-jc-between@md">
                        <header class="p-work__header | grid__col--12 grid__col--10@sm grid__col--3@md">
                            <h1 class="p-work__heading | t-t2">{{ page.catchphrase }}</h1>
                            <h2 class="p-work__title | t-sub">{{ page.title }}</h2>
                        </header>

                        <div class="grid__col--12 grid__col--10@sm grid__col--8@md">

                            <div class="grid__row">

                                <div class="p-work__data | grid__col--6">
                                    <h3 class="t-sub">{{ $t('misc.industry') }}</h3>
                                    <p
                                        v-for="(activityArea, i) in page.selectCategoryActivityArea"
                                        :key="`work-project-activity-area-${i}`"
                                    >
                                        {{ activityArea.title }}
                                    </p>
                                </div>

                                <div class="p-work__data | grid__col--6">
                                    <h3 class="t-sub">{{ $t('misc.year') }}</h3>
                                    {{ page.year }}
                                </div>

                                <div class="p-work__data | grid__col--12 grid__col--6@xs">
                                    <h3 class="t-sub">{{ $t('misc.whatWeDid') }}</h3>
                                    <list :items="subProjects">
                                        <template #default="item">
                                            <button
                                                :href="`#section-${item.slug}`"
                                                :title="item.title"
                                                class="c-list__link"
                                                v-cursor
                                                @click="scrollTo(`#section-${item.slug}`)"
                                            >
                                              {{ item.title }}
                                            </button>
                                        </template>
                                    </list>
                                </div>

                                <div class="p-work__data | grid__col--12 grid__col--6@xs">
                                    <template v-if="page.children.find(c => c.typeHandle === 'results')">
                                        <h3 class="t-sub">{{ $t('misc.results') }}</h3>

                                        <btn
                                            :label="$t('misc.results')"
                                            icon="award"
                                            @click.native="scrollTo('#results')"
                                        />
                                    </template>
                                    <template v-else-if="clientProjects.length > 0">
                                        <h3 class="t-sub">{{ $t('misc.otherProjects') }}</h3>
                                        <list :items="clientProjects" />
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div
            v-for="(child, i) in page.children"
            :key="`work-project-child-project-${i}`"
            :id="`section-${child.slug}`"
            class="p-work__projects"
        >
            <div
                :id="child.typeHandle === 'results' ? 'results' : child.slug"
                class="project__section"
            >
                <work-sub-project
                    v-if="child.typeHandle === 'subProject'"
                    :page="child"
                />
                <work-results
                    v-else-if="child.typeHandle === 'results'"
                    :page="child"
                />
            </div>
        </div>

    </div>
</template>

<script>
import { gsap, ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin)

import Asset from 'objects/Asset'
import Btn from 'components/Btn';
import List from 'components/List';
import CardProject from 'components/CardProject';
import WorkResults from 'views/WorkResults';
import WorkSubProject from 'views/WorkSubProject';

export default {
    name: 'WorkProject',
    components: {
        Asset,
        Btn,
        List,
        CardProject,
        WorkResults,
        WorkSubProject,
    },
    props: {
        page: Object,
    },
    data: () => ({
        clientProjects: []
    }),
    created() {
        const client = this.page.selectEntryClient
        if(client.length > 0) {

            const args = `relatedTo: ${client[0].id}, id: "not ${this.page.id}"`
            this.$store.dispatch('works/search', args)
                .then(projects => {
                    this.clientProjects = projects
                })
        }
    },
    computed: {
        headerImage() {
            const image = this.page.headerImage
            return image.length > 0 ? image[0].url : false
        },
        headerImageMobile() {
            const image = this.page.headerMobileImage
            return image.length > 0 ? image[0].url : false
        },
        subProjects() {
            return this.page.children.filter(c => c.typeHandle !== 'results')
        },
    },
    methods: {
        scrollTo(target) {

            gsap.to(window, {
                duration: .6,
                scrollTo: {
                    y: target,
                    offsetY: 150
                },
                ease: 'expo.out',
            });
        }
    }
};

</script>
