<template>
    <a
        class="c-card -project-upcoming"
        :href="externalLink"
        :title="title"
        target="_blank"
        rel="noopener"
        v-cursor.rotate="{ label: hoverText ? hoverText : $t('cursor.see') }"
        v-magnet
    >
        <asset
            v-if="asset"
            :asset="asset"
            :cover="true"
            :lazy="false"
            class="c-card__asset"
        />

        <div class="c-card__inner">

            <div class="c-card__content">
                <h3
                    v-if="catchphrase"
                    class="c-card__title | t-t3"
                >
                    {{ catchphrase }}
                </h3>

                <h4
                    v-if="title"
                    class="c-card__sub"
                >
                    {{ title }}
                </h4>
            </div>
        </div>
        <span class="js-magnet-hit"></span>
    </a>
</template>

<script>

import Asset from 'objects/Asset'

export default {
    name: 'CardProjectUpcoming',
    components: {
        Asset,
    },
    props: {
        title: {
            type: String,
            default: null
        },
        catchphrase: {
            type: String,
            default: null
        },
        externalLink: {
            type: String,
            default: null
        },
        imageSquare: {
            type: Array,
            default: null
        },
        hoverText: {
            type: String,
            default: null
        },
    },
    computed: {
        asset() {
            let asset = this.imageSquare
            asset = asset && asset.length > 0 ? asset[0] : false

            return asset
        },
    },
};

</script>

<style lang="scss">


.c-card.-project-upcoming {

    .c-card__asset {
        width: 100%;
        height: 0;
        padding-top: 100%;

        img {
            transform: scale(1.1);
        }

        &:hover {

            img {
                transform: scale(1);
            }
        }
    }
}


</style>
