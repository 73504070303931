import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'

// Views
import * as Components from './templates/views'

// Use plugin
Vue.use(VueRouter)

// Dynamic Routes from Craft
const initialData = window.__initialData__
const dynamicRoutes = initialData.routes.map(r => ({ ...r, component: Components[r.component] }));
const baseUrl = initialData.currentSite.baseUrl

// Static routes
const staticRoutes = [
    {
        name: 'Error404',
        path: '*',
        redirect: '/'
    },
]

// Merge dynamic and static routes
const routes = [...dynamicRoutes, ...staticRoutes]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: baseUrl,
    routes,
    linkActiveClass: 'is-active',
})

// Before each route load
router.beforeEach((to, from, next) => {

    // Next if projects page (filter)
    if(from.name === to.name && JSON.stringify(from.query) !== JSON.stringify(to.query) ) {
        next()
        return
    }

    store
        .dispatch('global/loadContent', to)
        .then(next)
        .catch(e => {

            if (e.code === 404) {
                next('/')
            }
        })
})

// Link resolver
const linkResolver = section => {

    const route = routes.find(route => route.meta.section === section)

    if(route) {
        return route.path
    } else {
        return '/'
    }
}


// Export
export default router
export { router, routes, linkResolver }
