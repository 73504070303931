export default {
    btn: {
        hireUs: 'Mandatez-nous',
        callUs: 'Nous appeler',
        send: 'Envoyer',
        all: 'Tout',
    },
    cursor: {
        dringDring: 'Dring Dring',
        workWithUs: 'Mandatez-nous',
        getDirection: 'Voir l’itinéraire',
        see: 'Voir',
    },
    header: {
        menu: 'Menu',
        close: 'Fermer',
    },
    footer: {
        updateLanguage: 'Changer de langue',
        getHere: `S'y rendre`,
    },
    misc: {
        email: 'Email',
        phone: 'Téléphone',
        year: 'Année',
        industry: 'Industrie',
        whatWeDid: 'Ce qu’on a fait',
        results: 'Résultats',
        tools: 'Outils',
        project: 'projet',
        otherTools: 'Quelques autres outils que nous utilisons',
        ourProjects: 'Nos projets',
        ourCulture: 'Notre culture',
        relatedProjectsTitle: 'Plus de projets qui pourraient vous intéresser.',
        otherProjects: 'Autres projets',
        awardsTitle: 'Awards et récompenses',
    },
    mandate: {
        success: {
            title: 'Votre courriel a été',
            sent: 'envoyé',
            desc: `Pendant que nous l'analysons, continuez à naviguer!`,
        },

        form: {
            fullname: 'Nom complet',
            organization: 'Organisation',
            phone: 'Téléphone',
            email: 'Adresse courriel',
            projectName: 'Nom du projet',
            projectDesc: 'Description du projet',
            pdfFile: 'Sélectionner un fichier PDF',
            budget: 'Budget',
            budget5K: '5K à 10K',
            budget10K: '10K à 20K',
            budget20K: '20K à 30K',
            budget30K: '30K à 50K',
            budget50K: '50K et plus',
            deliveryDate: 'Moment souhaité pour la livraison',
            deliveryDate3m: 'Dans 3 mois',
            deliveryDate6m: 'Dans 6 mois',
            deliveryDate1y: 'Dans 1 an',
            deliveryDateMore: 'Dans plus d’un an',
            deliveryDateUndefined: 'À déterminer',
        },
    },
}

// 'Awards and recognition'
// 'Tools'
// 'Shoutout to a couple of other tools !'
// 'Fran\u00e7ais'
// 'Get here'
// 'Get direction'
// 'Hire us'
// 'Work with us'
// 'Call us'
// 'Dring Dring'
// 'Close'
// 'Menu'
// 'Phone'
// 'Email'
// 'Industry'
// 'Year'
// 'What we did'
// 'Results'
// 'Other Projects'
