<template>
    <component
        v-if="text"
        :is="cms ? 'div' : tag"
        :class="className"
        v-html="text"
        v-reveal.once="reveal ? revealHandle : false"
    />
</template>

<script>

import { gsap, SplitText } from 'gsap/all'
gsap.registerPlugin(SplitText);

export default {
    name: 'AnimText',
    props: {
        text: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'span',
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        cms: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isVisible: false,
    }),
    created() {
        this.isVisible = this.visible
        // this.isVisible = true
    },
    mounted() {

        if(!this.cms) {
            this.split = new SplitText(this.$el, {
                type: 'lines, words',
                linesClass: 'o-at__l',
                wordsClass: 'o-at__w',
            })
        }
    },
    methods: {
        revealHandle(state) {
            this.isVisible = state.isActive
        },
    },
    computed: {
        className() {
            let classname = 'o-at'

            if(this.reveal) {
                classname += ' js-reveal'
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            if(this.cms) {
                classname += ' t-cms'
            }

            return classname
        }
    },
    watch: {
        isVisible(visible) {
            this.$emit('toggle', visible)

            if(visible) {
                this.$emit('show')
            } else {
                this.$emit('hide')
            }
        },
        visible(visible) {
            this.isVisible = visible
        },
    },
};

</script>

<style lang="scss">

.o-at {
    --a-at-in-delay: 0s;
    --a-at-in-ease: #{$out-quad};
    --a-at-in-duration: .6s;
    --a-at-out-delay: 0s;
    --a-at-out-ease: #{$in-sine};
    --a-at-out-duration: .3s;

    display: block;

    &.t-cms > * {
        opacity: 0;
        transform: translate(0, 1em);
        transition: transform var(--a-at-out-duration) var(--a-at-out-ease) var(--a-at-out-delay), opacity 0s calc(var(--a-at-out-duration) + var(--a-at-out-delay));
    }

    &.is-visible {

        .o-at__w {
            opacity: 1;
            transform: translate(0) rotate3d(0, 0, 0, 0) scale(1);
            transition: transform var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay), opacity 0s 0s;
        }

        &.t-cms > * {
            opacity: 1;
            transform: translate(0);
            transition: transform var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay), opacity var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay);
        }
    }

    @media (prefers-reduced-motion: reduce) {

        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}

.o-at__l {
    // overflow: hidden;
}

.o-at__w {
    opacity: 0;
    transform: translate(0, 50%) rotate3d(1, 0, -.1, -45deg) scale(1, .75);
    transform-origin: 50% 100%;
    transition: transform var(--a-at-out-duration) var(--a-at-out-ease) var(--a-at-out-delay), opacity 0s calc(var(--a-at-out-duration) + var(--a-at-out-delay));
    will-change: transform;

    .is-webkit em > &,
    .is-safari em > &,
    .is-mobile-safari em > & {
        margin: -.75em;
        padding: .75em;
    }

    .is-webkit &,
    .is-safari &,
    .is-mobile-safari & {
        transform: translate(0, 50%) scale(1, .75);
    }
}
</style>
