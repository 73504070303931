<template>
    <ul
        v-if="items && items.length > 0"
        :class="className"
    >
        <li
            v-for="(item, i) in items"
            :key="`list-${_uid}-${i}`"
            class="c-list__item"
        >
            <slot v-bind="item">
                <router-link
                    v-if="links && item.uri"
                    :to="`/${item.uri}`"
                    :title="item[keyLabel]"
                    class="c-list__link"
                    v-cursor
                >
                    {{ item[keyLabel] }}
                </router-link>
                <span v-else>
                    {{ item[keyLabel] }}
                </span>
            </slot>
        </li>
    </ul>
</template>

<script>

export default {
    name: 'List',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        border: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Boolean,
            default: true,
        },
        keyLabel: {
            type: String,
            default: 'title',
        },
    },
    computed: {
        className() {
            let classname = 'c-list'

            if(this.border) {
                classname += ' -border'
            }

            if(this.block) {
                classname += ' -block'
            }

            return classname
        }
    },
};

</script>

<style lang="scss">

.c-list {

    &.-border {

        .c-list__item {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(30, 30, 30, 0.15);
        }
    }
}

.c-list__item {
}

.c-list__link {
    display: inline-block;
    padding-top: .3em;
    padding-bottom: .3em;

    &:after {
        @include pseudo-el($height: .1em, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.c-list__text {
}


</style>
