<template>
    <div
        v-if="data.mediasGallery.length > 0"
        :class="className"
    >
        <gallery :items="data.mediasGallery">
            <template #item="item">
                <asset
                    v-if="item.typeHandle === 'image'"
                    :asset="item.source"
                    :lazy="false"
                />
                <vimeo
                    v-else-if="item.typeHandle === 'video'"
                    :id="item.video_id"
                    :background="item.backgroundMode"
                    :loop="item.isLoop"
                    :controls="item.hasControl"
                    :playInView="item.playInView"
                />
            </template>
        </gallery>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Vimeo from 'objects/Vimeo'
import Gallery from 'components/Gallery'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockMediasGallery',
    components: {
        Asset,
        Vimeo,
        Gallery,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-medias-gallery'

            classname +=  gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
    },
};

</script>

<style lang="scss">


</style>
