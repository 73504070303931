<template>
    <div
        v-if="awards.length > 0"
        class="b-awards"
    >
        <heading :suptitle="$t('misc.awardsTitle')" />

        <div class="b-awards__list">
            <div
                v-for="(award, i) in awards"
                :key="`block-awards-${_uid}-${i}`"
                class="b-awards__item | o-award"
            >
                <asset
                    :asset="award.logo"
                    class="b-awards__logo"
                />
                <h3>{{ award.heading }}</h3>
                <a
                    v-if="award.externalLink"
                    class="b-awards__link | t-t4"
                    :href="award.externalLink"
                    target="_blank"
                    rel="noopener"
                >
                    {{ award.buttonLabel }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Heading from 'objects/Heading'

export default {
    name: 'BlockAwards',
    components: {
        Asset,
        Heading,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        awards() {
            return this.data.awards
        },
    },
};

</script>

<style lang="scss">

.b-awards__list {
    display: grid;
    grid-gap: 2em var(--grid-gutter);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media #{md("sm")} {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    @media #{md("md")} {

        .b-content.-large & {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

.b-awards__item {
}

.b-awards__logo {
    display: block;
    margin-bottom: 1rem;

    img {
        width: auto;
        height: 1rem;
    }
}

.b-awards__link {
    display: block;
    margin-top: .5rem;
    color: $color-secondary;
}

</style>
