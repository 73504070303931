<template>
    <footer class="l-footer">

        <!-- Social block -->
        <div
            v-if="socialMedias && showSocialMedias"
            class="o-social | -bg-gradient-purple-red"
        >
            <div class="grid">
                <div class="o-social__box | grid__row">
                    <div class="grid__col--12 grid__col--6@sm">
                        <heading
                            :title="socialMedias.socialMediasTitle"
                            :suptitle="socialMedias.socialMediasSubtitle"
                        />
                    </div>
                    <div class="grid__col--12 grid__col--6@sm">
                        <ul class="o-social__list">
                                <li
                                    v-if="socialMedias.dribbble"
                                    class="o-social__item"
                                >
                                    <a
                                        :href="socialMedias.dribbble"
                                        title="Dribbble"
                                        target="_blank"
                                        rel="noopener"
                                        class="o-social__link"
                                        v-cursor
                                    >
                                        <icon icon="dribbble" />
                                        <span class="t-t5">Dribbble</span>
                                    </a>
                                </li>
                                <li
                                    v-if="socialMedias.behance"
                                    class="o-social__item"
                                >
                                    <a
                                        :href="socialMedias.behance"
                                        title="Behance"
                                        target="_blank"
                                        rel="noopener"
                                        class="o-social__link"
                                        v-cursor
                                    >
                                        <icon icon="behance" />
                                        <span class="t-t5">Behance</span>
                                    </a>
                                </li>
                                <li
                                    v-if="socialMedias.instagram"
                                    class="o-social__item"
                                >
                                    <a
                                        :href="socialMedias.instagram"
                                        title="Instagram"
                                        target="_blank"
                                        rel="noopener"
                                        class="o-social__link"
                                        v-cursor
                                    >
                                        <icon icon="instagram" />
                                        <span class="t-t5">Instagram</span>
                                    </a>
                                </li>
                                <li
                                    v-if="socialMedias.facebook"
                                    class="o-social__item"
                                >
                                    <a
                                        :href="socialMedias.facebook"
                                        title="Facebook"
                                        target="_blank"
                                        rel="noopener"
                                        class="o-social__link"
                                        v-cursor
                                    >
                                        <icon icon="facebook" />
                                        <span class="t-t5">Facebook</span>
                                    </a>
                                </li>
                                <li
                                    v-if="socialMedias.codepen"
                                    class="o-social__item"
                                >
                                    <a
                                        :href="socialMedias.codepen"
                                        title="CodePen"
                                        target="_blank"
                                        rel="noopener"
                                        class="o-social__link"
                                        v-cursor
                                    >
                                        <icon icon="codepen" />
                                        <span class="t-t5">Codepen</span>
                                    </a>
                                </li>
                                <li
                                    v-if="socialMedias.linkedin"
                                    class="o-social__item"
                                >
                                    <a
                                        :href="socialMedias.linkedin"
                                        title="GitHub"
                                        target="_blank"
                                        rel="noopener"
                                        class="o-social__link"
                                        v-cursor
                                    >
                                        <icon icon="linkedin" />
                                        <span class="t-t5">Linkedin</span>
                                    </a>
                                </li>
                        </ul>
                    </div>
                </div>
            </div>
            <figure
                v-if="footer.image.length > 0"
                class="o-social__fig"
            >
                <asset
                    :lazy="false"
                    :asset="footer.image"
                />
            </figure>

            <wave />
        </div>
        <!-- ./Social block -->

        <wave
            v-if="!showSocialMedias"
            class="l-footer__wave"
        />

        <!-- Contact block -->
        <div
            v-if="contact"
            class="o-contact | grid"
        >
            <div class="grid__row u-jc-between@sm">
                <div class="o-contact__heading | grid__col--12 grid__col--6@sm grid__col--5@lg">

                    <heading
                        v-if="heading"
                        :title="heading.sectionTitle"
                        tagTitle="h2"
                    />

                    <heading
                        v-if="heading"
                        :title="heading.sectionSubtitle"
                    />

                    <button
                        v-for="site in otherSites"
                        :key="`footer-local-${site.id}`"
                        class="o-contact__lang"
                        @click="updateLocale(site.id)"
                        v-cursor
                    >
                        {{ site.name }}
                    </button>
                </div>

                <div class="grid__col--12 grid__col--6@sm">
                    <ul class="o-contact__list">

                        <li
                            v-for="(address, i) in contact.address"
                            :key="`footer-address-${i}`"
                            class="o-contact__item"
                        >
                            <h3 class="o-contact__label | t-sub">{{ $t('footer.getHere') }}</h3>
                            <a
                                :href="`${address.map}?hl=${currentLocal}`"
                                :title="$t('footer.getHere')"
                                class="o-contact__link"
                                v-cursor.rotate="{ label: $t('cursor.getDirection'), color: 'var(--text-light)'}"
                            >{{ address.address }}</a>
                        </li>

                        <li
                            v-if="contact.hireUsMail"
                            class="o-contact__item"
                        >
                            <h3 class="o-contact__label | t-sub">{{ $t('btn.hireUs') }}</h3>
                            <a
                                :href="contact.hireUsMail"
                                :title="$t('btn.hireUs')"
                                class="o-contact__link"
                                v-cursor.rotate="{ label: $t('cursor.workWithUs'), color: 'var(--text-light)'}"
                            >
                                {{ contact.hireUsMail.replace('mailto:', '') }}
                            </a>
                        </li>

                        <li
                            v-if="contact.phoneNumber"
                            class="o-contact__item"
                        >
                            <h3 class="o-contact__label | t-sub">{{ $t('btn.callUs') }}</h3>
                            <a
                                :href="contact.phoneNumber|tel"
                                :title="$t('btn.callUs')"
                                class="o-contact__link"
                                v-cursor.rotate="{ label: $t('cursor.dringDring'), color: 'var(--text-light)'}"
                            >
                                {{ contact.phoneNumber.replace('tel:', '') }}
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <!-- ./Contact block -->

        <div class="grid">
            <router-link
                to="/"
                title="MamboMambo"
                class="o-contact__logo"
            >
               <icon icon="mambo" />
            </router-link>
        </div>
    </footer>

</template>

<script>

import Icon from 'objects/Icon'
import Wave from 'objects/Wave'
import Asset from 'objects/Asset'

import Heading from 'objects/Heading'

import { locales, currentLocal } from 'src/i18n'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AppFooter',
    components: {
        Icon,
        Wave,
        Asset,
        Heading,
    },
    props: {
        showSocialMedias: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        currentLocal,
    }),
    computed: {
        ...mapState({
            footer: state => state.global.footer,
            contact: state => state.global.contact,
        }),
        ...mapGetters({
            otherSites : 'global/otherSites',
            pageId: 'global/getCurrentPageID',
        }),
        heading() {
            return this.footer && this.footer.footerTitles.length > 0 ? this.footer.footerTitles[0] : false
        },
        socialMedias() {
            return this.footer && this.footer.socialMedias.length > 0 ? this.footer.socialMedias[0] : false
        },
        year: () => new Date().getFullYear(),
    },
    methods: {
        updateLocale(siteID) {
            // Load alternative url
            this.$store.dispatch('global/fetchPageUrl', { pageID: this.pageId, siteID})
                .then(url => {
                    // Load page
                    window.location.href = url
                })
        },
    },
};

</script>

<style lang="scss">

/*==============================
=            Footer            =
==============================*/


.l-footer {
    z-index: 1;
}

.l-footer__wave {
    top: -1px;
    background-color: var(--color-bg);
}


/*----------  Social Block  ----------*/

.o-social {
    overflow: hidden;

    // If no figure
    .o-wave:nth-child(2) {
        position: relative;
        left: calc(50% - 50vw);
        width: 100vw;
        margin-top: vh(10);
    }
}

.o-social__box {
    padding: 2em 1.5em;
    background-color: $color-light;
    box-shadow: $box-shadow;

    @media #{md("xs")} {
        padding: 3em 2em;
    }

    @media #{md("sm")} {
        padding: 6em 3em;
    }
}

.o-social__list,
.o-contact__list {
    display: grid;
    grid-gap: 1em;
    margin-top: 2.5em;

    @media #{md("xs")} {
        grid-row-gap: 2em;
        margin-top: 2.5em;
    }

    @media #{md("sm")} {
        grid-row-gap: 3em;
    }

    @media #{md("md")} {
        grid-row-gap: 4em;
    }
}

.o-social__list {
    grid-template-columns: repeat(2, 1fr);

    @media #{md("sm")} {
        margin-top: 1.5em;
    }
}

.o-social__item {}

.o-social__link {
    display: inline-flex;
    align-items: center;

    .o-icon.-linkedin {
        top: -.2em;
    }

    span {
        display: block;
        margin-left: 1em;
    }
}

.o-social__fig {
    margin-top: -2em;
    width: 80%;

    img {
        @include img;
    }

    @media #{md("xs")} {
        margin-top: -3em;
    }

    @media #{md("sm")} {
        margin-top: -15%;
        width: 60%;
    }
}


/*----------  Contact block  ----------*/


.o-contact {
    padding-bottom: 2em;

    .o-heading:nth-child(n+2) {
        margin-top: 2em;
    }

    @media #{md("xs")} {
        padding-bottom: 3em;
    }

    @media #{md("sm")} {
        // padding-bottom: 6em;
    }

    @media #{md("md")} {

        .o-heading:nth-child(n+2) {
            margin-top: 3em;
        }
    }
}

.o-contact__heading {

    @media #{md("sm")} {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}

.o-contact__lang {
    display: inline-block;
    margin-top: 2em;
    padding: 0;

    @media #{md("sm")} {
        // margin-top: auto;
        margin-bottom: .3em;
    }
}

.o-contact__lang,
.o-contact__link {
    @include linear-gradient(to bottom, transparent calc(100% - .1em), currentColor 0);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.o-contact__list {

    @media #{md("sm")} {
        margin-top: 0;
    }
}

.o-contact__link {
    display: inline;
    line-height: 1.6;
}

.o-contact__label {
    margin-bottom: 1em;
}

/*=====  End of Contact block  ======*/


.o-contact__logo {
    display: block;
    overflow: hidden;

    .o-icon.-mambo {
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc(39/223 * 100%);
        margin-bottom: -1%;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

</style>
