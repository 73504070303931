<template>
    <div class="b-content-list">
        <list
            class="b-content-list__text"
            :items="data.list"
            keyLabel="item"
        />
        <list
            class="b-content-list__cards"
            :items="data.cards"
        >
            <template #default="item">
                <div class="b-content-list__card">
                    <asset
                        :asset="item.squareImage"
                    />
                    <heading
                        :suptitle="item.suptitle"
                        :title="item.headline"
                        classTitle="t-t3"
                    />
                </div>
            </template>
        </list>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText'
import Heading from 'objects/Heading'
import List from 'components/List'

export default {
    name: 'BlockContentList',
    components: {
        Asset,
        AnimText,
        Heading,
        List,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
};

</script>

<style lang="scss">

.b-content-list {
}

.b-content-list__text {

    .c-list__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1em;

        &:before {
            content: "/";
            display: block;
            width: 1em;
            flex-shrink: 0;
        }
    }
}

.b-content-list__cards {
    display: grid;
    grid-gap: 2em var(--grid-gutter-half);
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &:not(:first-child) {
        margin-top: 2rem;
    }

    .o-asset {
        margin-bottom: 1rem;
    }

    @media #{md("xs")} {

        .o-heading {
            padding: 1rem;
        }
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media #{md("md")} {

        .b-content.-large & {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

</style>
