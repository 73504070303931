<template>
    <div :class="className">
        <component
            v-for="(component, i) in data.children"
            :key="`block-content-children-${i}`"
            :is="component.typeHandle"
            :data="component"
        />
    </div>
</template>

<script>

import BlockContentHeadline from 'builder/BlockContentHeadline'
import BlockContentText from 'builder/BlockContentText'
import BlockContentList from 'builder/BlockContentList'
import BlockContentImage from 'builder/BlockContentImage'
import BlockContentButtons from 'builder/BlockContentButtons'
import BlockContentVideo from 'builder/BlockContentVideo'
import BlockSpacer from 'builder/BlockSpacer'
import BlockResult from 'builder/BlockResult'
import BlockProjectTools from 'builder/BlockProjectTools'
import BlockAwards from 'builder/BlockAwards'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockContent',
    components: {
        BlockContentHeadline,
        BlockContentText,
        BlockContentList,
        BlockContentImage,
        BlockContentButtons,
        BlockContentVideo,
        BlockSpacer,
        BlockResult,
        BlockProjectTools,
        BlockAwards,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        settings() {
            return this.data.blockSettings[0]
        },
        className() {
            let classname = 'b-content'

            classname += gridColumnsClasses(this.settings)

            if(this.settings.mdCol == 0 || this.settings.mdCol > 7) {
                classname += ' -large'
            }

            return classname
        }
    }
};

</script>

<style lang="scss">

.b-content {

    .b-content-text:nth-child(n+2),
    .b-content-headline:nth-child(n+2) {
        margin-top: 1.25rem;
    }

    .b-content-buttons:nth-child(n+2) {
        margin-top: 2rem;
    }
}

</style>
