<template>
    <i :class="className">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let classname = `o-icon -${this.icon}`

            if(this.size) {
                classname += ` -${this.size}`
            }

            return classname;
        }
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

.svg-mambo {
    --svg-width: 5.5em;
    --svg-ratio: 39/223;
}

.svg-mambomambo {
    --svg-width: 5.5em;
    --svg-ratio: 88/216;
}

.svg-palm {
    --svg-width: 1.25em;
    --svg-ratio: 35/39;
}

.svg-buoy {
    --svg-width: 1em;
}

.svg-circles {
    --svg-width: 1.25em;
}

.svg-award {
    --svg-width: 1em;
    --svg-ratio: 20/17;
}

.svg-shoutout {
    --svg-width: 1.5em;
    --svg-ratio: 17/21;
}

.svg-github,
.svg-behance,
.svg-codepen,
.svg-dribbble,
.svg-linkedin,
.svg-facebook,
.svg-instagram {
    --svg-width: 1em;
}

.svg-github {
    --svg-ratio: 11/13;
}

.svg-behance {
    --svg-ratio: 33/50;
}

.svg-codepen {
    --svg-ratio: 31/30;
}


</style>
