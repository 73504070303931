<template>
    <div class="b-content-buttons">
        <btn
            v-for="(btn, i) in buttons"
            :tag="btn.tag"
            :href="btn.href"
            :label="btn.label"
            :modifier="btn.modifier"
            :icon="btn.icon"
            :key="`block-button-${_uid}-${i}`"
        />
    </div>
</template>

<script>

import Btn from 'components/Btn'

export default {
    name: 'BlockContentButtons',
    components: {
        Btn,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        buttons() {
            const buttons = []

            let href
            this.data.buttons.forEach(btn => {
                let entry = btn.selectedEntry
                entry = entry.length > 0 ? entry[0] : false
                href = entry ? `/${entry.uri}` : btn.outerLink ? btn.outerLink : false

                if(href) {

                    buttons.push({
                        tag: entry ? 'router-link' : 'a',
                        href,
                        label: entry && !btn.label ? entry.title : btn.label,
                        modifier: btn.color,
                        icon: btn.icon,
                    })
                }
            })

            return buttons
        }
    }
};

</script>

<style lang="scss">

.b-content-buttons {}

</style>
