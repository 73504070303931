<template>
    <div
        class="b-project-tools"
        v-if="tools.length > 0"
    >
        <heading :suptitle="$t('misc.tools')" />
        <list
            :items="tools"
            :border="true"
            class="b-project-tools__list"
        />
    </div>
</template>

<script>

import List from 'components/List'
import Heading from 'objects/Heading'

import { mapState } from 'vuex'

export default {
    name: 'BlockProjectTools',
    components: {
        List,
        Heading,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage
        }),
        tools() {
            return this.currentPage.selectEntryTools
        },
    },
};

</script>

<style lang="scss">

.b-project-tools {}

.b-project-tools__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media #{md("sm")} {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    @media #{md("md")} {

        .b-content.-large & {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media #{md("lg")} {

        .b-content.-large & {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

// .b-project-tools__tool {
//     min-width: 8.5em;
// }


</style>
