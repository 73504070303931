<template>
    <div :class="className">
        <heading :title="data.headline" />

        <div
            v-if="tools.length > 0"
            class="o-tools"
        >
            <div
                v-for="(toolType, i) in toolTypes"
                :key="`block-tools-${_uid}-${i}`"
                class="o-tools__toolType"
                :style="`--total-tools: ${getRelatedTools(toolType.id).length};`"
            >
                <h3 class="o-tools__title | t-sub u-c-grey-light">
                    {{ toolType.title.toUpperCase() }}
                </h3>
                <ul class="o-tools__list">
                    <li
                        v-for="(tool, j) in getRelatedTools(toolType.id)"
                        :key="`block-tools-${toolType.slug}-${j}`"
                        class="o-tools__item"
                    >
                        <asset
                            :asset="tool.logo"
                            class="o-tools__logo"
                        />
                        <h2 class="o-tools__name | t-t4">{{ tool.title }}</h2>
                        <p
                            v-if="tool.relatedProjects.length > 0"
                            class="o-tools__count | t-t5"
                        >
                            {{ tool.relatedProjects.length }} {{ $t('misc.project') }}{{ tool.relatedProjects.length > 1 ? 's' : '' }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="b-tools__other">
            <h3 class="title t-sub color-light-grey">
                {{ $t('misc.otherTools') }}
            </h3>
            <list
                :items="otherTools"
                :border="true"
                class="b-tools__list"
            />
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Heading from 'objects/Heading'
import List from 'components/List'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockTools',
    components: {
        Asset,
        Heading,
        List,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        toolTypes: [],
        tools: [],
        otherTools: [],
    }),
    created() {
        this.$store.dispatch('misc/loadAll', { stateKey: 'tools', query: 'tools' })
            .then(data => {
                const tools = []

                // Get only tools that have a toolType
                let toolType
                const toolTypes = []
                data.forEach(tool => {
                    let toolType = tool.selectCategoryToolType

                    if(toolType.length > 0) {
                        toolType = toolType[0]
                        tool.category = toolType
                        tools.push(tool)

                        if(toolTypes.findIndex(t => t.id === toolType.id) === -1) {
                            toolTypes.push(toolType)
                        }
                    } else {
                        this.otherTools.push(tool)
                    }
                })

                // Sort toolsType by order field
                toolTypes.sort((a, b) => (a.order > b.order) ? 1 : -1)

                // Sort tools by AZ order
                tools.sort(function(a, b) {
                    var textA = a.title.toUpperCase();
                    var textB = b.title.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                this.tools = tools
                this.toolTypes = toolTypes
            })
    },
    computed: {
        className() {
            let classname = 'b-tools'

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
    },
    methods: {
        getRelatedTools(categoryID) {
            return this.tools.filter(t => t.category.id === categoryID)
        },
    },
};

</script>

<style lang="scss">

.b-tools {

    .c-content {
        text-align: center;
    }
}

.b-tools__other {

    .c-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media #{md("sm")} {
            margin-top: 2rem;
            margin-bottom: 4rem;
        }

        @media #{md("md")} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media #{md("lg")} {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}


/*=================================
=            Tool list            =
=================================*/

.o-tools {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.o-tools__toolType {
    --width: calc(var(--total-tools) * 1/2 * 100%);

    width: var(--width);
    max-width: 100%;
    padding-top: 2rem;
    margin-bottom: 1em;

    @media #{md("xs")} {
        --width: calc(var(--total-tools) * 1/3 * 100%);
    }

    @media #{md("sm")} {
        --width: calc(var(--total-tools) * 1/4 * 100%);
    }

    @media #{md("md")} {
        --width: calc(var(--total-tools) * 1/5 * 100%);
    }

    @media #{md("lg")} {
        --width: calc(var(--total-tools) * 1/6 * 100%);
    }
}

.o-tools__title {
}

.o-tools__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.o-tools__item {
    flex: 1 1 0;
    padding-top: 1em;
    padding-bottom: 1em;
    min-width: calc(1/3 * 100%);

    @media #{md("xs")} {
        min-width: calc(1/5 * 100%);
    }

    @media #{md("sm")} {
        min-width: calc(1/6 * 100%);
    }
}

.o-tools__logo {
    width: 100%;
    height: 3.25em;

    img {
        width: auto;
        height: 100%;
    }
}

.o-tools__name {
    margin: 0.75rem 0 .25rem;
}

.o-tools__count {
    color: $color-secondary;
}


</style>
