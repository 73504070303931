<template>
    <div v-if="page.title">
        <structured-datas-job :job="page" />
        <builder :data="page.layoutPage" />
    </div>
</template>

<script>
import StructuredDatasJob from 'objects/StructuredDatasJob.vue'
import Builder from 'builder/Builder';

export default {
    name: 'Job',
    components: {
        Builder,
        StructuredDatasJob
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seoPage ? this.page.seoPage.title : null
        }
    },
};

</script>

<style lang="scss">

.p-job {}

</style>
