<template>
    <span :class="className">
        <span
            ref="bg"
            class="o-loader__bg"
            :style="`--size: ${bgSize}px;`"
        >
            <span class="o-loader__gradients"></span>
        </span>
        <span
            v-if="firstload"
            class="o-loader__inner"
        >
            <i
                v-for="i in 2"
                :key="`first-load-${i}`"
                class="o-loader__icon"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223 39">
                    <defs>
                        <linearGradient id="mambomambo-gradient" x1="100%" y1="100%">
                            <stop offset="0%" stop-color="lightblue" stop-opacity=".3">
                                <animate attributeName="stop-color" values="lightblue;blue;red;purple;orange;lightblue" dur="8s" repeatCount="indefinite" />
                            </stop>
                            <stop offset="100%" stop-color="lightblue" stop-opacity=".3">
                                <animate attributeName="stop-color" values="lightblue;orange;purple;red;blue;lightblue" dur="8s" repeatCount="indefinite" />
                            </stop>
                        </linearGradient>
                    </defs>
                    <path fill="url(#mambomambo-gradient)" d="M0,1.5h12.4L25,28.9L37.7,1.5h12.4v36.6h-8.8V12.2L29.2,38.1h-8.3L8.8,12.2v25.9H0V1.5z"/>
                    <path fill="url(#mambomambo-gradient)" d="M57.1,36.9c-2.1-1.4-3.1-3.2-3.1-5.5c0-2.4,1.2-4.4,3.7-6c2.5-1.6,6-2.6,10.4-3l8.2-0.8
                        c0.6-0.1,1.1-0.3,1.5-0.6c0.4-0.3,0.6-0.7,0.6-1.2c0-0.9-0.7-1.6-2.1-2.3c-1.4-0.6-3-0.9-5-0.9c-2.4,0-4.7,0.4-6.8,1.3
                        c-2.1,0.9-3.7,1.9-4.6,3.2l-5.3-3.3c0.8-1.3,2-2.5,3.7-3.5c1.7-1,3.7-1.8,6-2.4c2.3-0.6,4.6-0.9,7-0.9c2.9,0,5.5,0.4,7.8,1.2
                        c2.3,0.8,4.1,2,5.5,3.5c1.3,1.5,2,3.3,2,5.4v10.1c0,0.6,0.2,1.1,0.5,1.5c0.3,0.4,0.8,0.5,1.3,0.5c0.5,0,1-0.1,1.5-0.4
                        c0.5-0.2,0.9-0.5,1.2-0.9l2.2,4.3c-0.8,0.8-1.8,1.5-3.2,2c-1.4,0.5-2.8,0.7-4.3,0.7c-2,0-3.7-0.5-5-1.5c-1.4-1-2.1-2.3-2.3-3.9
                        l-0.1-0.4c-1.1,1.8-2.8,3.2-5.1,4.3C71,38.5,68.4,39,65.5,39C62,39,59.2,38.3,57.1,36.9z M73.2,32.5c1.5-0.5,2.7-1.3,3.6-2.2
                        c0.9-0.9,1.4-2,1.5-3.1v-1.1l-8.4,0.7c-2.5,0.2-4.4,0.7-5.6,1.3c-1.3,0.6-1.9,1.4-1.9,2.3c0,0.9,0.5,1.6,1.6,2.2
                        c1,0.5,2.5,0.8,4.3,0.8C70.1,33.3,71.7,33.1,73.2,32.5z"/>
                    <path fill="url(#mambomambo-gradient)" d="M95.1,11.8h6.9l1.2,5.1c0.9-1.9,2.3-3.4,4-4.4c1.8-1,3.8-1.5,6-1.5c2.6,0,4.9,0.6,6.7,1.8
                        c1.9,1.2,3.2,2.9,4.1,5.1c0.8-2.1,2.2-3.8,4.1-5c1.9-1.2,4.2-1.8,6.8-1.8c3.6,0,6.5,1,8.7,3.1c2.2,2.1,3.3,5,3.3,8.7v15.3h-8.4
                        V24.3c0-2.2-0.6-3.9-1.8-5.1c-1.2-1.2-2.8-1.8-4.9-1.8c-2,0-3.7,0.6-4.9,1.8c-1.3,1.2-1.9,2.9-1.9,5.1v13.8h-8.4V24.3
                        c0-2.2-0.6-3.9-1.7-5.1c-1.2-1.2-2.7-1.8-4.8-1.8c-2,0-3.7,0.6-4.9,1.8c-1.2,1.2-1.9,2.9-1.9,5.1v13.8h-8.4V11.8z"/>
                    <path fill="url(#mambomambo-gradient)" d="M164.2,37.3c-2-1.1-3.6-2.7-4.6-4.7l-1.2,5.5h-7.1V0h8.4v17.3c1-2,2.6-3.5,4.6-4.7c2-1.1,4.4-1.7,7-1.7
                        c2.8,0,5.3,0.6,7.5,1.7c2.2,1.2,3.9,2.8,5.2,4.9c1.2,2.1,1.9,4.6,1.9,7.3s-0.6,5.2-1.9,7.3c-1.3,2.1-3,3.8-5.2,4.9
                        c-2.2,1.2-4.7,1.7-7.5,1.7C168.6,38.9,166.3,38.4,164.2,37.3z M173.1,31.5c1.4-0.6,2.5-1.5,3.2-2.7c0.8-1.1,1.2-2.4,1.2-3.8
                        s-0.4-2.7-1.2-3.8c-0.8-1.1-1.9-2-3.2-2.6c-1.4-0.6-2.9-1-4.7-1c-1.7,0-3.3,0.3-4.7,1c-1.4,0.6-2.5,1.5-3.2,2.6
                        c-0.8,1.1-1.2,2.4-1.2,3.8s0.4,2.7,1.2,3.8c0.8,1.1,1.9,2,3.2,2.7c1.4,0.7,2.9,1,4.7,1C170.2,32.4,171.7,32.1,173.1,31.5z"/>
                    <path fill="url(#mambomambo-gradient)" d="M196.3,37.2c-2.6-1.2-4.7-2.8-6.2-4.9c-1.5-2.1-2.2-4.5-2.2-7.3c0-2.8,0.7-5.2,2.2-7.3
                        c1.5-2.1,3.5-3.7,6.2-4.9c2.6-1.2,5.7-1.7,9.1-1.7c3.4,0,6.5,0.6,9.1,1.7c2.7,1.2,4.7,2.8,6.2,4.9c1.5,2.1,2.2,4.5,2.2,7.3
                        c0,2.7-0.7,5.2-2.2,7.3c-1.5,2.1-3.5,3.8-6.2,4.9c-2.7,1.2-5.7,1.7-9.1,1.7C202,38.9,199,38.4,196.3,37.2z M210.2,31.7
                        c1.4-0.7,2.5-1.6,3.3-2.7c0.8-1.2,1.2-2.5,1.2-4s-0.4-2.8-1.2-4c-0.8-1.2-1.9-2.1-3.3-2.8c-1.4-0.7-3-1-4.7-1
                        c-1.8,0-3.3,0.3-4.7,1s-2.5,1.6-3.2,2.8c-0.8,1.2-1.2,2.5-1.2,4s0.4,2.8,1.2,4c0.8,1.2,1.9,2.1,3.2,2.7c1.4,0.7,3,1,4.7,1
                        C207.2,32.7,208.8,32.3,210.2,31.7z"/>
                </svg>
            </i>
        </span>
    </span>
</template>


<script>

import Variables from 'src/mixins/variables';

import { mapGetters } from 'vuex'
import { gsap } from 'gsap/all'

export default {
    name: 'Loader',
    mixins: [ Variables ],
    data: () => ({
        loading: true,
        animating: false,
        firstload: true,
    }),
    created() {
        this.$store.dispatch('loader/startLoad')

        let firstLoadStart = window.loaderStart

        const loaderWatch = this.$store.watch(() => this.$store.getters['loader/loadCount'], count => {

            if(count === 1) {
                let delay = (firstLoadStart - new Date) + 3000
                delay = delay < 0 ? 0 : delay

                    setTimeout(() => {
                        this.$store.dispatch('loader/endLoad')

                        // Remove watch
                        loaderWatch()
                    }, delay);
            }
        })
    },
    mounted() {
        setTimeout(() => {
            this.animating = true
        }, 10);

        document.addEventListener('mousemove', this.move)
    },
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),

        className() {
            let classname = 'o-loader'

            if(this.firstload) {
                classname += ` -firstload`
            }

            if(this.animating) {
                classname += ' -animate'
            }

            if(this.loading) {
                classname += ' is-loading'
            }

            return classname
        },

        bgSize() {
            const windowHypot = Math.hypot(this.W.w, this.W.h)
            return Math.ceil(windowHypot)
        },
    },
    methods: {
        animateIn() {
            //console.log('animateIn')
            this.$store.dispatch('loader/startLoad')

            this.loading = true;

            gsap.to(this.$refs.bg, {
                duration: .8,
                x: 0,
                y: 0,
                scale: 1,
                ease: 'power3.inOut',
                onComplete: () => {
                    window.scrollTo(0, 0)
                    this.$store.dispatch('loader/endLoad')
                }
            })
        },
        animateOut() {
            //console.log('animateOut')
            this.loading = false;

            gsap.to(this.$refs.bg, {
                duration: 1,
                scale: 0,
                ease: 'expo.out',
                delay: this.firstload ? .4 : 0,
                onComplete: () => {
                    this.firstload = false
                }
            })
        },

        move(e) {
            if(this.loading || this.firstload) {
                return
            }

            gsap.set(this.$refs.bg, {
                x: e.clientX - this.W.w/2,
                y: e.clientY - this.W.h/2,
            })
        },
    },
    watch: {
        isLoading(loading) {
            if(loading) {
                this.animateIn()
            } else {
                this.animateOut()
            }
        }
    },
}

</script>

<style lang="scss">

/*===============================
=            Loader             =
===============================*/


.o-loader {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0s 1s, z-index 0s 1s;

    &.-firstload {
        transition: opacity 0s 1.4s, z-index 0s 1.4s;

        .o-loader__bg {
            transform: scale(1);
        }

        .o-loader__gradients {
            display: none;
        }

        &.is-loading.-animate {

            .o-loader__inner {
                transform: translate(0);
                transition: transform .4s $in-out-quad;
            }
        }
    }

    &.is-loading {
        z-index: 2000;
        opacity: 1;
        pointer-events: initial;
        transition: none;

        .o-loader__gradients {
            --animation-state: running;
        }
    }
}

.o-loader__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    transform: translate(0, 100%);
    transition: transform .4s $out-quad;
}

.o-loader__icon {
    --size: 99vw;
    width: 100vw;
    margin-bottom: calc(-.01 * var(--size));
    will-change: transform;

    svg {
        display: block;
        width: var(--size);
        height: calc(var(--size) * (39/223));
    }

    &:nth-child(1) {
        animation: firstloadLogoLoop 4s linear infinite;
    }

    &:nth-child(2) {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(100%, 0);
        animation: firstloadLogoIn calc(4s/2) linear, firstloadLogoLoop 4s linear calc(4s/2) infinite;
    }
}



$color-gradient-red: rgba(#e01919, .5);
$color-gradient-blue: #274ee5;
$color-gradient-yellow: rgba(#ffe603, 1);
$color-gradient-green: #8ce894;

.o-loader__bg {
    --size: 0px;
    position: fixed;
    top: calc(50% - var(--size)/2);
    left: calc(50% - var(--size)/2);
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: $color-light;
    overflow: hidden;
    transform: scale(0);
    transform-origin: 50% 50%;
    will-change: transform;

    &:after {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        @include linear-gradient(to bottom, $color-light, rgba(#fff, 0));
    }
}

.o-loader__gradients {
    --animation-state: paused;

    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    &:after,
    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        background-size: auto 100%;
        background-repeat: repeat-x;
        opacity: 0;
    }

    &:before {
        @include linear-gradient(to bottom, $color-gradient-red, $color-gradient-blue);
        animation: loaderGradient 4s infinite var(--animation-state);
    }

    &:after {
        @include linear-gradient(to bottom, $color-gradient-yellow, $color-gradient-green);
        animation: loaderGradient 4s infinite var(--animation-state) calc(4s/2);
    }
}

@keyframes loaderGradient {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    60% {
        opacity: .5;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


@keyframes firstloadLogoIn {
    0% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes firstloadLogoLoop {
    0% {
        transform: translate(0);
    }
    50% {
        transform: translate(-100%, 0);
    }
    50.0001% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(0);
    }
}

</style>
