<template>
    <div>
        <div class="p-home__palm">
            <video
                v-if="video"
                loop autoplay muted
            >
                <source
                    :src="video.url"
                    :type="video.mimeType"
                >
            </video>
            <asset
                v-else
                url="/static/images/palm-tree.png"
                :lazy="false"
                title="Palm tree"
            />
        </div>
        <builder :data="page.layoutPage" />
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Builder from 'builder/Builder';

export default {
    name: 'Home',
    components: {
        Asset,
        Builder,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seoPage ? this.page.seoPage.title : null
        }
    },
    computed: {
        video() {
            const video = this.page.headerVideo

            return video && video.length > 0 ? video[0] : false
        },
    },
};

</script>

<style lang="scss">

.p-home {}

.p-home__palm {
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    height: auto;
    pointer-events: none;

    .o-asset {
        width: calc(1/2 * 100%);
        float: right;
        display: block;

        @media #{md("sm", "max")} {
            width: calc(2/3 * 100%);
        }
    }

    video {
        width: 70%;
        transform: translate3d(30%, 0, 0);
        float: right;
        display: block;

        @media #{md("sm", "max")} {
            width: 80%;
            transform: translate3d(20%, 5%, 0);
        }
    }
}

</style>
