<template>
    <component
        :is="tag"
        class="c-card -member"
    >
        <div
            v-if="asset"
            class="c-card__asset"
        >
            <asset
                :asset="asset"
                :title="title"
                class="c-card__img"
            />

            <ul
                v-if="socials.length > 0"
                class="c-card__social"
            >
                <li
                    v-for="(social, i) in socials"
                    :key="`card-member-${_uid}-${i}`"
                >
                    <a
                        :href="social.socialUrl"
                        target="_blank"
                        rel="noopener"
                        v-cursor="{ color: 'radial-gradient(#ffffff, rgba(255, 255, 255, 0))' }"
                    >
                        <icon :icon="social.socialLabel.toLowerCase()" />
                    </a>
                </li>
            </ul>
        </div>

        <div class="c-card__content">
            <h3 class="c-card__title | t-t3">{{ title }}</h3>

            <h4
                v-if="sub"
                class="c-card__sub"
            >
                {{ sub }}
            </h4>

            <a
                v-if="phone"
                class="c-card__phone"
                :title="`${$t('misc.phone')} ${ title }`"
                :href="phoneHref(phone)"
                target="_blank"
                rel="noopener"
                v-cursor
            >
                {{ phone }}
            </a>

            <a
                v-if="email"
                class="c-card__email"
                :title="`${$t('misc.email')} ${ title }`"
                :href="`mailto:${ email }`"
                target="_blank"
                rel="noopener"
                v-cursor
            >
                {{ email }}
            </a>
        </div>
    </component>

</template>

<script>

import Icon from 'objects/Icon'
import Asset from 'objects/Asset'

export default {
    name: 'CardMember',
    components: {
        Icon,
        Asset,
    },
    props: {
        tag: {
            type: String,
            default: 'li'
        },
        title: {
            type: String,
            default: null
        },
        sub: {
            type: String,
            default: null
        },
        email: {
            type: String,
            default: null
        },
        phone: {
            type: String,
            default: null
        },
        asset: {
            type: String | Object | Array,
            default: null
        },
        socials: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        phoneHref(number) {
            return `tel:+${number.replace(' ', '').replace('#', ',')}`
        },
    },
};

</script>

<style lang="scss">

/*===================================
=            Member card            =
===================================*/


.c-card.-member {

    .c-card__asset {

        &:before {
            @include pseudo-el();
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            @include linear-gradient(to top, rgba($color-primary, .75), rgba(255, 255, 255, 0));
            transform: translate(0, 100%);
            transition: transform .3s ease-in-out;
        }

        img {
            transform: scale(1.1);
            transition: transform .3s ease-in-out;
        }

        &:hover {

            &:before {
                transform: translate(0);
                transition: transform .3s ease-out;
            }

            .c-card__social {
                opacity: 1;
                transition: opacity .3s ease-out;
            }

        }
    }
}

.c-card__social {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    transform: translate(0, -50%);
    opacity: 0;
    transition: opacity .3s ease-in-out;

    li {

        &:nth-child(n+2) {
            margin-left: .2em;
        }

    }

    a {
        display: flex;
        width: 2.25em;
        height: 2.25em;
        color: $color-light;
    }

    svg {
        display: block;
        width: .75em;
        height: .75em;
        margin: auto;
    }
}

.c-card__phone {
    display: block;
    margin-top: 1em;
}

.c-card__email {
    display: block;
    margin-top: .5em;
}


</style>
