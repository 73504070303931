<template>
    <div :class="className">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path :d="path" />
        </svg>
    </div>
</template>

<script>

import { isInViewport } from 'src/utils'

export default {
    name: 'Wave',
    props: {
        pos: String,
        default: null,
    },
    data: () => ({
        path: 'M 0 0',
        height: 0,
        delta: 40,
        speed: 0.3,
        points: 2,
        lastUpdate: null,
        totalTime: 0,
        rect: {}
    }),
    mounted() {
        this.rect = this.$el.getBoundingClientRect()

        this.loop(true);

        window.addEventListener('resizeEnd', this.resize);
        window.addEventListener('scroll', this.scroll);
    },
    computed: {
        className() {
            let classname = 'o-wave'

            if(this.pos) {
                classname += ` -${this.pos}`
            }

            return classname
        }
    },
    methods: {
        buildPath(factor) {
            let points = [];

            for (let i = 0; i <= this.points; i++) {
                let x = i / this.points * this.rect.width;
                let sinSeed = (factor + (i + i % this.points)) * this.speed * 100;
                let sinHeight = Math.sin(sinSeed / 100) * this.delta;
                let y = Math.sin(sinSeed / 100) * sinHeight + this.height;

                points.push({x, y});
            }

            // Svg stuff
            let SVGString = `M ${points[0].x} ${points[0].y}`

            let cp0 = {
                x: (points[1].x - points[0].x) / 2,
                y: (points[1].y - points[0].y) + points[0].y + (points[1].y - points[0].y)
            };

            SVGString += ` C ${cp0.x} ${cp0.y} ${cp0.x} ${cp0.y} ${points[1].x} ${points[1].y}`;

            let prevCp = cp0;

            for (let i = 1; i < points.length - 1; i++) {
                let cp1 = {
                    x: (points[i].x - prevCp.x) + points[i].x,
                    y: (points[i].y - prevCp.y) + points[i].y
                };

                SVGString += ` C ${cp1.x} ${cp1.y} ${cp1.x} ${cp1.y} ${points[1+1].x} ${points[1+1].y}`;
                prevCp = cp1;
            }

            SVGString += ` L ${this.rect.width} ${this.rect.height}`;
            SVGString += ` L 0 ${this.rect.height} Z`;
            return SVGString;
        },

        resize() {
            this.rect = this.$el.getBoundingClientRect()

            // Make wave smaller on mobile
            this.delta = window.innerWidth > 768 ? 40 : 20;
        },

        scroll() {
            this.rect = this.$el.getBoundingClientRect()
        },

        loop(init=false) {

            if (init === true || isInViewport(this.rect)) {

                let now = window.Date.now();

                if (this.lastUpdate) {
                    let elapsed = (now - this.lastUpdate) / 1000;
                    this.lastUpdate = now;

                    this.totalTime += elapsed;

                    let factor = this.totalTime * Math.PI;

                    this.path = this.buildPath(factor)
                } else {
                    this.lastUpdate = now;
                }
            }

            window.requestAnimationFrame(this.loop.bind(this));
        },
    },

    destroyed() {
        //remove listener and loop
        window.cancelAnimationFrame(this.loop);
        window.removeEventListener('resizeEnd', this.resize);
    }
};

</script>

<style lang="scss">

.o-wave {
    --height: 120px;

    z-index: 10;
    display: block;
    //position: absolute;
    //bottom: -1px;
    //left: 0;
    //width: 100%;
    max-width: none;
    height: var(--height);
    transform-origin: 50% 0;

    @include center-viewport;
    overflow: hidden;
    bottom: -1px;

    &.-top {
        top: -1px;
        bottom: auto;
        margin-top: calc(.5 * var(--height));
        backface-visibility: hidden;
        transform: rotateZ(180deg);
    }

    &.-bottom {
        margin-bottom: calc(.5 * var(--height));
    }

    svg {
        overflow: visible;
        width: 100%;
        height: inherit;
    }

    @media #{md("sm")} {
        --height: 200px;
    }

    path {
        fill: $color-light;
    }
}

</style>
