import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
** Misc
*/

// State
const state = {
    members: {},
    tools: {},
}

// Getters
const getters = {
    getMemberById: state => id => state.members[id],
    getToolById: state => id => state.tools[id],
}

// Actions
const actions = {
    loadAll(store, {stateKey, query}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            fetchApi(queries[query])
                .then(r => {
                    const entries = r.entries

                    entries.forEach(entry => {
                        store.commit('add', { stateKey, id: entry.id, data: entry })
                    })

                    resolve(entries)

                    // End load
                    store.dispatch('loader/endLoad', null, {root: true});
                })
        })
    },
    loadMultiple(store, {stateKey, query, ids}) {

        if(!Array.isArray(ids)) {
            return false
        }

        const promises = []
        ids.forEach(id => promises.push(store.dispatch('load', { stateKey, query, id })))

        return Promise.all(promises)
    },
    load(store, {stateKey, query, id}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if content already exists
            let entry
            if(stateKey === 'members') {
                entry = store.getters.getMemberById(id)
            } else if(stateKey === 'tools') {
                entry = store.getters.getToolById(id)
            }

            // If entry already exists, resolve with the entry value
            if(typeof entry !== 'undefined') {
                resolve(entry)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If category doesn't exist, load category
            } else {

                fetchApi(queries[query](id))
                    .then(r => {
                        const data = r.entry
                        store.commit('add', { stateKey, id, data })
                        resolve(data)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
}

// Mutations
const mutations = {
    add(state, {stateKey, id, data}) {
        state[stateKey][id] = data
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
