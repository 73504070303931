/*
** Cradft GraphQL Queries
*/


import store from 'src/store'

import snippets from './snippets'
import { getAsset } from './assets'

const siteID = window.__initialData__.currentSite.id || 2



///////////////////////////////////////
// GLOBALS
///////////////////////////////////////

// General data
export const general = `{
    parameters: globalSet(handle: "site", siteId: "${siteID}") {
        ... on site_GlobalSet {
            menu {
                ...on menu_BlockType {
                    entry {
                        ${snippets.defaultData}
                        sectionHandle
                    }
                    label
                }
            }
            footerTitles {
                ...on footerTitles_BlockType {
                    sectionTitle
                    sectionSubtitle
                }
            }
            ${getAsset('image')}
            socialMedias {
                ...on socialMedias_BlockType {
                    socialMediasTitle
                    socialMediasSubtitle
                    dribbble
                    behance
                    instagram
                    facebook
                    codepen
                    linkedin
                }
            }
            ${getAsset('instagramStory')}
            ${getAsset('placeholder')}
        }
    }
    contact: globalSet(handle: "contact", siteId: "${siteID}") {
        ... on contact_GlobalSet {
            contactInfos {
                ...on contactInfos_BlockType {
                    address {
                        address
                        map
                    }
                    hireUsMail
                    phoneNumber
                }
            }
        }
    }
}`


///////////////////////////////////////
// ENTRIES
///////////////////////////////////////

// Homepage
export const home = `{
    entry(section: "home", siteId: "${siteID}") {
        ${snippets.entry}

        ...on home_home_Entry {
            ${snippets.pageSettings}
            ${snippets.seoPage}
            ${snippets.neoLayoutPage}
            headerVideo {
                ${snippets.defaultData}
                mimeType
            }
        }
    }
}`

// Projects
export const projects = `{
    entry(section: "projects", siteId: "${siteID}") {
        ${snippets.entry}

        ...on projects_projects_Entry {
            ${snippets.pageSettings}
            ${snippets.seoPage}
            headline
            selectCategoryService {
                ${snippets.defaultData}
            }
        }
    }
}`

// Contact
export const culture = `{
    entry(section: "culture", siteId: "${siteID}") {
        ${snippets.entry}

        ...on culture_culture_Entry {
            ${snippets.pageSettings}
            ${snippets.seoPage}
            ${snippets.neoLayoutPage}
        }
    }
}`

// Contact
export const contact = `{
    entry(section: "contact", siteId: "${siteID}") {
        ${snippets.entry}

        ...on contact_contact_Entry {
            ${snippets.pageSettings}
            ${snippets.seoPage}
            ${snippets.neoLayoutPage}
        }
    }
}`

// Mandate
export const mandate = `{
    entry(section: "mandate", siteId: "${siteID}") {
        ${snippets.entry}

        ...on mandate_mandate_Entry {
            ${snippets.pageSettings}
            ${snippets.seoPage}
            ${snippets.neoLayoutPage}
        }
    }
}`

// Members
export const members = `{
    entries(section: "members", siteId: "${siteID}") {
        ${snippets.entry}

        ...on members_members_Entry {
            ${getAsset('image')}
            subtitle
            memberEmail
            memberPhone
            memberSocials {
                ...on memberSocials_BlockType {
                    socialLabel
                    socialUrl
                }
            }
        }
    }
}`

// Member
export const member = id => `{
    entry(section: "members", id: "${id}", siteId: "${siteID}") {
        ${snippets.entry}

        ...on members_members_Entry {
            ${getAsset('image')}
            subtitle
            memberEmail
            memberPhone
            memberSocials {
                ...on memberSocials_BlockType {
                    socialLabel
                    socialUrl
                }
            }
        }
    }
}`

// Tools
export const tools = `{
    entries(section: "tools", siteId: "${siteID}") {
        ${snippets.entry}

        ...on tools_tools_Entry {
            ${getAsset('logo')}
            selectCategoryToolType {
                ${snippets.defaultData}
                order: lft
            }
            relatedProjects {
                ${snippets.defaultData}
            }
        }
    }
}`

// Tool
export const tool = id => `{
    entry(section: "tools", id: "${id}", siteId: "${siteID}") {
        ${snippets.entry}

        ...on tools_tools_Entry {
            ${getAsset('logo')}
            selectCategoryToolType {
                ${snippets.defaultData}
                order: lft
            }
            relatedProjects {
                ${snippets.defaultData}
            }
        }
    }
}`

// Jobs
export const job = slug => `{
    entry(section: "job", slug: "${slug}", siteId: "${siteID}") {
        ${snippets.entry}
        datePost: postDate @formatDateTime
        dateUpdated @formatDateTime
        expiryDate @formatDateTime

        ...on job_job_Entry {
            description: body
            refNumber: text
            ${snippets.pageSettings}
            ${snippets.seoPage}
            ${snippets.neoLayoutPage}
        }
    }
}`

// WORK
export const work = slug => `{
    entry(section: "work", slug: "${slug}", isArchived: false, siteId: "${siteID}") {
        ${snippets.entry}

        ...on work_project_Entry {
            typeHandle

            # SEO
            ${snippets.seoProject}

            # Display
            ${getAsset('headerImage')}
            ${getAsset('headerMobileImage')}
            selectEntryClient {
                ${snippets.defaultData}
            }
            selectCategoryActivityArea {
                ${snippets.defaultData}
            }
            selectCategoryLocation {
                ${snippets.defaultData}
            }
            year
            isArchived
            isUpcoming
            externalLink

            # Card
            featured
            backgroundType
            ${getAsset('image', 'cardProject')}
            ${getAsset('backgroundImage', 'cardProject')}
            color
            hoverText
            hoverLight
            catchphrase

            # Tools
            selectEntryTools {
                ${snippets.defaultData}
            }

            # Colors
            ${snippets.siteColors}

            children {
                typeHandle
                ${snippets.entry}
                ${snippets.workSubproject}
                ${snippets.workResults}
            }
        }

        ${snippets.workSubproject}
        ${snippets.workResults}
    }
}`

export const workPartial = id => `{
    entry(section: "work", id: "${id}", isArchived: false, siteId: "${siteID}") {
        ${snippets.workPartial}
    }
}`


export const worksPartial = `{
    entries(section: "work", type: "project", isArchived: false, siteId: "${siteID}") {
        ${snippets.workPartial}
    }
}`

export const workSearch = query => `{
    entries(section: "work", ${query}, isArchived: false, siteId: "${siteID}") {
        ${snippets.workPartial}
    }
}
`

export const pageURL = (pageID, site) => `{
    entry(id: ${pageID}, siteId: "${site}") {
        url
    }
}
`



export default {
    // Globals
    general,

    // Entries
    home,
    projects,
    culture,
    contact,
    mandate,
    job,

    // Work
    work,
    workPartial,
    worksPartial,
    workSearch,

    // Misc
    member,
    members,
    tool,
    tools,
    pageURL
}
