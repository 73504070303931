<template>
    <spacer />
</template>

<script>
import Spacer from 'objects/Spacer'

export default {
    name: 'BlockSpacer',
    components: {
        Spacer
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
};

</script>

<style lang="scss">

</style>
