<template>
    <div class="b-website | grid__col--12">
        <a
            v-if="data.externalLink"
            :href="data.externalLink"
            :title="data.subtitle"
            class="b-website__link"
            :style="`color: ${ data.selectColor }; background-color: ${ data.color };`"
            v-magnet
        >
            <span
                v-if="data.subtitle"
                class="b-website__label | t-t4 | js-magnet-inner"
                data-magnet-scale="0.9"
                data-magnet-move="40"
            >
                {{ data.subtitle }}
            </span>
            <span
                class="b-website__url | t-t2 || js-magnet-inner"
            >
                {{ data.externalLink.replace('https://', '').replace('http://', '').replace('www.', '') }}
                <span class="js-magnet-hit" v-cursor.hidden=""></span>
            </span>
        </a>
    </div>
</template>

<script>

export default {
    name: 'BlockWebsite',
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
};

</script>

<style lang="scss">

.b-website {}

.b-website__link {
    display: block;
    width: 100%;
    padding: 6rem;
    text-align: center;

    &:hover {

        .b-website__url:after {
            transform: scale(1);
            transform-origin: 0 0;
        }
    }

    @media #{md("sm", "max")} {
        padding: 4rem 2rem;
    }

    @media #{md("xs", "max")} {
        padding: 3rem 1rem;
    }
}

.b-website__label {
    display: block;
    margin-bottom: 0.25em;
}

.b-website__url {
    display: inline-block;

    &:after {
        @include pseudo-el($height: .1em, $bg: currentcolor);
        position: absolute;
        top: 100%;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 0;
        transition: transform 0.4s ease-in-out;
    }
}

</style>
