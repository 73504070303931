<template>
    <div>
        <work-project
            v-if="page.typeHandle === 'project'"
            :page="page"
        />
        <work-sub-project
            v-else-if="page.typeHandle === 'subProject'"
            :page="page"
        />
        <work-results
            v-else-if="page.typeHandle === 'results'"
            :page="page"
        />

        <!-- Related Projects -->
        <section v-if="relatedProjects.length > 0">
            <div class="grid">
                <div class="title">
                    <div class="grid__row u-jc-start">
                        <div class="grid__col--12 grid__col--9@sm grid__col--5@md grid__col--4@lg">
                            <heading
                                :title="$t('misc.relatedProjectsTitle')"
                                tagTitle="h2"
                            />
                        </div>
                    </div>
                </div>

                <div class="b-projects">

                    <slider modifier="projects">
                        <div
                            v-for="(project, i) in relatedProjects"
                            :key="`work-related-${i}`"
                            class="c-slider__slide"
                        >
                            <card-project v-bind="project"/>
                        </div>
                    </slider>
                </div>
            </div>
        </section>
        <!-- ./Related Projects -->
    </div>
</template>

<script>

import WorkProject from 'views/WorkProject';
import WorkResults from 'views/WorkResults';
import WorkSubProject from 'views/WorkSubProject';
import Slider from 'components/Slider';
import Heading from 'objects/Heading';
import CardProject from 'components/CardProject';

import { mapGetters } from 'vuex'

export default {
    name: 'Work',
    components: {
        WorkProject,
        WorkResults,
        WorkSubProject,
        Slider,
        Heading,
        CardProject,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seoProject ? this.page.seoProject.title : null
        }
    },
    data: () => ({
        relatedProjects: [],
    }),
    created() {
        const args = `type: "project", id: "not ${this.pageId}", search: "-isUpcoming:* -featured:*", orderBy: "RAND()", limit: 3`
        this.$store.dispatch('works/search', args)
            .then(projects => {
                this.relatedProjects = projects
            })
    },
    computed: {
        ...mapGetters({
            pageId: 'global/getCurrentPageID',
        }),
    },
};

</script>

<style lang="scss">

.p-work {}

.p-work__cover {
    display: block;

    @media #{md("sm", "max")} {
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-image: var(--image);
        background-position: 50% 50%;
        background-size: cover;

        img {
            display: none;
            visibility: hidden;
        }
    }

    @media #{md("sm")} {

        img {
            @include img;
        }
    }
}

.p-work__title:nth-child(2) {
    margin-top: .75rem;
}

.p-work__data {

    .t-sub {
        margin-bottom: .5rem;
    }

    @media #{md("md", "max")} {
        margin-top: 2em;
    }

    @media #{md("md")} {

        &:nth-child(n+3) {
            margin-top: 2em;
        }
    }
}


</style>
