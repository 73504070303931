<template>
    <div
        v-if="totalSlides > 0"
        :class="className"
    >
        <slider v-if="totalSlides > 4">
            <div
                v-for="(slide, i) in data.imageSlider"
                v-if="slide.image.length > 0"
                :key="`block-image-slider-${_uid}-${i}`"
                class="c-slider__slide"
            >
                <asset :asset="slide.image" />
                <span class="u-dp-block u-ta-center">{{ slide.label }}</span>
            </div>
        </slider>
        <div
            v-else
            class="u-dp-flex u-jc-between"
        >
            <div
                v-for="(slide, i) in data.imageSlider"
                v-if="slide.image.length > 0"
                :key="`block-image-slider-${_uid}-${i}`"
                :style="`width: calc(${(100/totalSlides).toFixed(2)}% - 1rem)`"
                class="slide"
            >
                <asset :asset="slide.image" />
                <span class="u-dp-block u-ta-center">{{ slide.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Slider from 'components/Slider'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockImageSlider',
    components: {
        Asset,
        Slider,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-image-slider'

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
        totalSlides() {
            return this.data.imageSlider.length
        },
    },
};

</script>

<style lang="scss">

.b-image-slider {}

</style>
