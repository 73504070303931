<template>
    <div>
        <builder :data="page.layoutPage" />

        <spacer />

        <div class="grid">
            <div ref="mandate">

                <transition name="anim-fade" mode="out-in">

                    <form
                        v-if="!success"
                        method="post"
                        action="/"
                        enctype="multipart/form-data"
                        accept-charset="UTF-8"
                        class="c-form"
                        ref="form"
                        @submit="checkForm"
                    >
                        <accordion
                            v-for="(field, i) in fields"
                            :key="field.name"
                            class="c-form__item"
                            ref="accordions"
                            @click.native="activeFieldIndex = i"
                        >
                            <template #title>
                                <h3 class="c-form__label | t-t2">
                                    {{ field.label }}
                                </h3>
                                <span class="c-form__open">
                                    {{ field.label }}
                                </span>
                            </template>
                            <div
                                class="c-form__el"
                                :class="`-${field.type}`"
                            >

                                <div
                                    class="o-input"
                                    :class="`-${field.type}`"
                                >

                                    <!-- Text input -->
                                    <input
                                        v-if="field.type !== 'radio' && field.type !== 'textarea'"
                                        v-model="field.value"
                                        :name="field.name"
                                        :required="field.required"
                                        :type="field.type"
                                        class="o-input__text | t-t2 || js-form-input"
                                    >
                                    <!-- ./Text input -->

                                    <!-- Textarea -->
                                    <div v-else-if="field.type === 'textarea'">
                                        <textarea
                                            v-model="field.value"
                                            :name="field.name"
                                            :required="field.required"
                                            class="o-input__text | t-t2 || js-form-input"
                                        >
                                        </textarea>
<!--                                             <div
                                            v-if="field.nested"
                                            class="o-input -file"
                                        >
                                            <input
                                                :id="`${name}-nested`"
                                                :name="field.nested.name"
                                                :required="field.nested.required"
                                                type="file"
                                                class="o-input__file"
                                                @change="inputFileHandler"
                                            >
                                            <btn
                                                :label="field.nested.label"
                                                class="o-input__label"
                                                tag="label"
                                                :for="`${name}-nested`"
                                                iconAfter="download"
                                            />
                                            <btn
                                                :label="field.nested.text"
                                                class="o-input__label is-active"
                                                tag="span"
                                                iconAfter="cross"
                                                v-show="field.nested.text"
                                                @click.prevent
                                            />
                                        </div> -->
                                    </div>
                                    <!-- ./Textarea -->

                                    <!-- Radios -->
                                    <ul
                                        v-else-if="field.type === 'radio'"
                                        class="o-input__list || js-form-input"
                                    >
                                        <li
                                            v-for="(option, i) in field.options"
                                            :key="option.label"
                                            class="o-input__item"
                                        >
                                            <input
                                                :id="`${field.name}-${i}`"
                                                v-model="field.value"
                                                :name="field.name"
                                                :required="field.required"
                                                :value="option.label"
                                                type="radio"
                                                class="o-input__radio"
                                            >
                                            <btn
                                                tag="label"
                                                :label="option.label"
                                                class="o-input__label"
                                                :for="`${field.name}-${i}`"
                                            />
                                        </li>
                                    </ul>
                                    <!-- ./Radios -->

                                </div>
                            </div>
                        </accordion>

                        <btn
                            :label="$t('btn.send')"
                            class="c-form__submit"
                            modifier="md"
                            tag="button"
                            icon="circles"
                        />
                    </form>

                    <div
                        v-else
                        class="p-success"
                    >
                        <h2 class="p-success__title | t-t2">
                            {{ $t('mandate.success.title') }}
                            <span class="t-special">{{ $t('mandate.success.sent') }}</span>
                        </h2>
                        <h3 class="p-success__sub | t-t3">{{ $t('mandate.success.desc') }}</h3>

                        <div class="p-success__btns">
                            <btn
                                tag="router-link"
                                :href="'projects'|resolver"
                                :label="$t('misc.ourProjects')"
                                modifier="md"
                                icon="circles"
                            />
                            <btn
                                tag="router-link"
                                :href="'culture'|resolver"
                                :label="$t('misc.ourCulture')"
                                modifier="md"
                                icon="circles"
                            />
                        </div>
                    </div>
                </transition>

                <btn
                    v-if="contact.phoneNumber"
                    type="circle"
                    tag="a"
                    :href="contact.phoneNumber|tel"
                    :label="$t('btn.callUs')"
                    icon="shoutout"
                />

            </div>
        </div>

    </div>
</template>

<script>

import Builder from 'builder/Builder';
import Btn from 'components/Btn';
import Accordion from 'components/Accordion';
import Spacer from 'objects/Spacer'

import { currentLocal } from 'src/i18n'
import Variables from 'src/mixins/variables';

import { mapState } from 'vuex'
import { gsap, ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Mandate',
    components: {
        Builder,
        Btn,
        Accordion,
        Spacer
    },
    mixins: [ Variables ],
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seoPage ? this.page.seoPage.title : null
        }
    },
    data() {
        return {
            data: {},
            success: false,
            waiting: false,
            $activeEl: undefined,
            activeFieldIndex: -1,
            fields: [
                {
                    label: this.$t('mandate.form.fullname'),
                    value: '',
                    type: 'text',
                    id: 'from-name',
                    name: 'fromName',
                },
                {
                    label: this.$t('mandate.form.organization'),
                    value: '',
                    type: 'text',
                    name: 'message.organization',
                },
                {
                    label: this.$t('mandate.form.phone'),
                    value: '',
                    type: 'tel',
                    name: 'message.phone',
                },
                {
                    label: this.$t('mandate.form.email'),
                    value: '',
                    type: 'email',
                    name: 'fromEmail',
                },
                {
                    label: this.$t('mandate.form.projectName'),
                    value: '',
                    type: 'text',
                    name: 'subject',
                },
                {
                    label: this.$t('mandate.form.projectDesc'),
                    value: '',
                    type: 'textarea',
                    name: 'message.description',
    /*
                    nested: {
                        label: this.$t('mandate.form.pdfFile'),
                        type: 'file',
                        name: 'attachment',
                        text: ''
                    }
    */
                },
                {
                    label: this.$t('mandate.form.budget'),
                    value: '',
                    type: 'radio',
                    name: 'message.budget',
                    options: [
                        {
                            id: '5-10',
                            label: this.$t('mandate.form.budget5K'),
                        },
                        {
                            id: '10-20',
                            label: this.$t('mandate.form.budget10K'),
                        },
                        {
                            id: '20-30',
                            label: this.$t('mandate.form.budget20K'),
                        },
                        {
                            id: '30-50',
                            label: this.$t('mandate.form.budget30K'),
                        },
                        {
                            id: '50+',
                            label: this.$t('mandate.form.budget50K'),
                        },
                    ]
                },
                {
                    label: this.$t('mandate.form.deliveryDate'),
                    value: '',
                    type: 'radio',
                    name: 'message.delivery',
                    options: [
                        {
                            id: '3mo',
                            label: this.$t('mandate.form.deliveryDate3m'),
                        },
                        {
                            id: '6mo',
                            label: this.$t('mandate.form.deliveryDate6m'),
                        },
                        {
                            id: '12mo',
                            label: this.$t('mandate.form.deliveryDate1y'),
                        },
                        {
                            id: '12mo+',
                            label: this.$t('mandate.form.deliveryDateMore'),
                        },
                        {
                            id: 'todetermine',
                            label: this.$t('mandate.form.deliveryDateUndefined'),
                        }
                    ]
                }
            ]
        }
    },
    mounted() {

        // Remove active item if click out of form
        document.addEventListener('click', this.clickHandle);

        // Bind key inputs
        document.addEventListener('keydown', this.keyHandle)
    },
    computed: {
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue,
        ...mapState({
            contact: state => state.global.contact,
        }),
//         defaultData() {
//             const data = {
//                 message: {}
//             }
//
//             data[this.csrfName] = this.csrfToken
//             data.message.template = 'contact'
//
//             return data
//         },
        totalFields() {
            return this.fields.length
        },
    },
    methods: {
        clickHandle(e) {
            if(!this.$refs.form.contains(e.target)) {
                this.activeFieldIndex = -1
            }
        },
        keyHandle(e) {
            let key = e.keyCode

            if(key === 37) {
                e.preventDefault()
                this.activeFieldIndex = this.activeFieldIndex > 0 ? this.activeFieldIndex - 1 : -1
            } else if(key === 9 || key === 39) {
                e.preventDefault()
                this.activeFieldIndex = this.activeFieldIndex < this.totalFields ? this.activeFieldIndex + 1 : this.totalFields
            }
        },
        openAccordion(i) {
            this.currentAccordion = this.$refs.accordions[i]

            if(typeof this.currentAccordion === 'undefined') {
                return
            }

            //console.log('openAccordion', i)

            this.currentAccordion.open()
            this.activeFieldIndex = i

            // Focus active text input
            this.$currentInput = this.currentAccordion.$el.querySelector('.js-form-input')

            if(this.$currentInput.type !== 'radio' && this.$currentInput.type !== 'file') {
                this.$currentInput.focus()
            }
        },
        // inputFileHandler(e) {
        //     let $target = e.target
        //     this.fields.projectDescription.nested.value = $target.value
        //     this.fields.projectDescription.nested.text = $target.files[0].name
        // },
        validate(string, type='') {
            let re
            switch(type) {
                case 'email':
                    re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    break;
                case 'tel':
                    re = /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/
                    break;
                default:
                    re = /.*/g
            }
            return re.test(string);
        },
        checkForm(e) {
            e.preventDefault()

            // Remove focus from active input
            this.activeFieldIndex = -1

            let field, input, offset
            let i = 0
            for(let field in this.fields) {
                input = this.fields[field]

                // Form validation
                if( input.value == '' || !this.validate(input.value, input.type)) {
                    this.activeFieldIndex = i
                    break
                }

                i++

                // All fields looped
                if(i == Object.keys(this.fields).length) {
                    this.submit()
                }
            }
        },
        submit() {

            if (this.waiting === true) {
                return
            }

            this.success = false
            this.waiting = true

            // Create form data
            const formData = new FormData();
            formData.append('action', 'contact-form/send');

            // Add hidden inputs
            formData.append(this.csrfName, this.csrfToken)
            formData.append('message[template]', 'contact')
            formData.append('message[lang]', currentLocal)

            let fieldName
            this.fields.forEach(field => {

                fieldName = field.name
                if(typeof field.value !== 'undefined') {

                    if(fieldName.startsWith('message.')) {
                        fieldName = fieldName.replace('message.', '')
                        //data.message[fieldName] = field.value
                        formData.append(`message[${fieldName}]`, field.value)
                    } else {
                        //data[fieldName] = field.value
                        formData.append(fieldName, field.value)
                    }
                }
            })

            fetch('/contact-form/send', {
                    method: 'POST',
                    body: formData
                })
                .then(r => r.text())
                .then(data => {
                    this.success = true
                    this.waiting = false
                    this.$emit('success')

                    // Resset
                    setTimeout(this.reset, 3000);

                    gsap.to(window, {
                        scrollTo: {
                            y: 0,
                        },
                        duration: 1,
                        ease: 'expo.out',
                    })
                })
                .catch((e) => {
                    console.log('Submit error: ', e)
                    this.waiting = false
                })
        },
    },
    watch: {
        activeFieldIndex(index, oldIndex) {
            //console.log('activeFieldIndex', index)

            if(index === oldIndex) {
                return
            }

            // Hide old input
            if(oldIndex > 0 && this.fields[oldIndex].value === '') {
                this.currentAccordion.close()
            }

            if(index < 0) {
                return
            }

            this.openAccordion(index)
        }
    },
    beforeDestroy() {

        // Remove click handle
        document.removeEventListener('click', this.clickHandle);

        // Remove key binding
        document.removeEventListener('keydown', this.keyHandle)
    }
};

</script>

<style lang="scss">

.p-mandate {

    .c-btn-circle {
        z-index: 100;
        position: sticky;
        bottom: 1em;
        left: 50%;
        margin-top: 1em;
        transform: translate(-50%, 0);
    }

    .p-success {}

    .p-success__title {
        margin-bottom: .5em;
    }

    .p-success__sub {
        margin-bottom: 1.5em;
    }

    .p-success__btns {
        margin: -.5em;

        .c-btn {
            margin: .5em;
        }
    }

    @media #{md("xs")} {

        .c-btn-circle {
            left: 100%;
            transform: none;
        }
    }
}


/*================================
=            Formular            =
================================*/


.c-form {

    &:after {
        @include pseudo-el($height: vh(50));
        @include linear-gradient(to bottom, rgba(#fff, 0), #fff);
        z-index: 1;
        position: fixed;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }
}

.c-form__item {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    color: $color-dark-light;
    transition: all .4s ease-in-out;

    &.is-open {
        padding-top: vh(5);
        padding-bottom: vh(5);
        color: $color-dark;
    }

    &.is-filled,
    &.is-open {

        .c-form__open,
        .o-input__text {
            opacity: 1;
            transform: rotate3d(1, 0, 0, 0) scale(1);
            transition: opacity .2s ease-out .2s, transform .2s ease-out .2s;
        }

        .c-form__label {
            opacity: 0;
            transform: rotate3d(-1, 0, 0, 90deg) scale(1, .7);
            transition: opacity .2s ease-in, transform .2s ease-in;
        }

        .o-input__item {
            z-index: 0;
            opacity: 1;
            transform: scale(1);

            @for $i from 1 to 10 {
                $i: $i - 1;

                &:nth-child(#{$i}) {
                    transition: all .2s $out-quad #{.2 + $i/10 * .5}s;
                }
            }
        }
    }

    @media #{md("sm")} {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

.c-form__el {
    display: inline-block;

    &.-textarea {
        width: 100%;
    }
}

.c-form__label {
    z-index: 1;
    // height: 100%;
    display: inline-block;
    pointer-events: none;
    user-select: none;
    transform-origin: 50% 0;
    transition: opacity .2s ease-out .2s, transform .2s ease-out .2s;
}

.c-form__open {
    position: absolute;
    left: 0;
    top: 1rem;
    font-family: ff("supline");
    font-size: .6rem;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0;
    transform: rotate3d(1, 0, 0, 90deg) scale(1, .7);
    transform-origin: 50% 100%;
    transition: opacity .2s ease-in 0s, transform .2s ease-in 0s;
}

.c-form__submit {
    margin-top: 2em;
}


/*----------  Inputs  ----------*/

.o-input {
    // margin-top: -2.5em;

    &.-textarea {

        .o-input__text {
            width: 100%;
            height: 6em;
        }
    }

    &.-radio {
    }

    &.-file {
        margin-top: 0;

        .o-input__label {
            display: inline-block;
        }
    }
}

.o-input__text {
    width: 100%;
    height: inherit;
    color: inherit;
    border: 0;
    background: none;
    opacity: 0;
    transform: rotate3d(1, 0, 0, 90deg) scale(1, .7);
}

.o-input__list {
    // display: flex;
    // flex-wrap: wrap;
    margin: -.2em 0;
}

.o-input__item {
    z-index: -1;
    display: inline-block;
    margin: .5em .7em;
    opacity: 0;
    transform: scale(.8);

    @for $i from 1 to 10 {
        $i: $i - 1;

        &:nth-child(#{$i}) {
            transition: all .2s $in-quad 0s;
        }
    }
}

.o-input__radio {
    display: none;

    &:checked + .o-input__label {
        --color-bg: #{$color-primary-light};
        --color-border: transparent;

        .c-btn__bg {
            // background-color: $color-primary-light;
        }

        &:before {
            // opacity: 0;
        }
    }
}

.o-input__label {
    display: block;
}

.o-input__file {
    display: none;
}


</style>
