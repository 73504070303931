import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
** Works
*/

// State
const state = {
    works: [],
    allPreloaded: false,
    allLoaded: false,
}

// Getters
const getters = {
    getProjectById: state => id => state.works.find(work => work.id === id),
}

// Actions
const actions = {
    preloadAll(store) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});
            if(store.state.allPreloaded) {
                resolve(store.state.works)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            } else {
                fetchApi(queries.worksPartial)
                    .then(r => {
                        const works = r.entries

                        works.forEach(work => {

                            work.isSingleFetch = false
                            store.commit('addWork', work)
                        })

                        resolve(works)
                        store.commit('setAllPreloaded', true)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }
        })
    },
    preloadMultiple(store, ids) {

        if(!Array.isArray(ids)) {
            return false
        }

        const promises = []
        ids.forEach(id => promises.push(store.dispatch('preload', id)))

        return Promise.all(promises)
    },
    preload(store, id) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if project already exists
            const project = store.getters.getProjectById(id)

            // If entry already exists, resolve with the entry value
            if(typeof project !== 'undefined') {
                resolve(project)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If category doesn't exist, load category
            } else {
                fetchApi(queries.workPartial(id))
                    .then(r => {
                        const work = r.entry
                        work.isSingleFetch = false
                        store.commit('addWork', work)
                        resolve(work)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
    search(store, query) {

        // Start load
        store.dispatch('loader/startLoad', null, {root: true});

        return new Promise((resolve, reject) => {
            fetchApi(queries.workSearch(query))
                .then(r => {
                    const works = r.entries

                    works.forEach(work => {

                        if(typeof store.getters.getProjectById(work.id) === 'undefined') {
                            work.isSingleFetch = false
                            store.commit('addWork', work)
                        }
                    })

                    resolve(works)

                    // End load
                    store.dispatch('loader/endLoad', null, {root: true});
                })
        })
    }
}

// Mutations
const mutations = {
    addWork(state, data) {
        state.works.push(data)
    },
    setAllPreloaded(state, allPreloaded) {
        state.allPreloaded = allPreloaded
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
