<template>
    <div
        v-if="items.length > 0"
        class="c-gallery"
    >
        <div
            v-for="(item, i) in items"
            :key="`gallery-${_uid}-${i}`"
            class="c-gallery__item"
            :style="item.width ? `--width: ${item.width}%` : false"
        >
            <slot name="item" v-bind="item"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Gallery',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};

</script>

<style lang="scss">

.c-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.c-gallery__item {
    --width: 100%;

    width: var(--width);
}

</style>
