<template>
    <ul
        v-if="members.length > 0"
        :class="className"
    >
        <card-member
            v-for="(member, i) in members"
            :key="`block-team-${_uid}-${i}`"
            :title="member.title"
            :sub="display.job ? member.subtitle : null"
            :email="display.email ? member.memberEmail : null"
            :phone="display.phone ? member.memberPhone : null"
            :asset="!display.photo ? null : member.image.length > 0 ? member.image[0] : ''"
            :socials="member.memberSocials"
        />
    </ul>
</template>

<script>

import CardMember from 'components/CardMember'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockTeam',
    components: {
        CardMember,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        members: [],
    }),
    created() {
        let members = this.data.selectEntriesMember
        if(members.length > 0) {
            members = members.map(m => m.id)
            this.$store.dispatch('misc/loadMultiple', { stateKey: 'members', query: 'member', ids: members })
                .then(data => {
                    this.members = data
                })
        }
    },
    computed: {
        className() {
            let classname = 'b-team'

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
        display() {
            return this.data.blockTeamDisplay[0]
        },
    },
};

</script>

<style lang="scss">

.b-team {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, 1fr);
    }
}

</style>
