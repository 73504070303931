<template>
    <div>
        <component
            v-for="(component, i) in data"
            :key="`builder-component-${i}`"
            :is="component.typeHandle"
            :data="component"
        />
    </div>
</template>

<script>

import BlockContainer from 'builder/BlockContainer'

export default {
    name: 'Builder',
    components: {
        BlockContainer,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
};

</script>

<style lang="scss">

</style>
