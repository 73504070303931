<template>
    <div
        v-if="asset"
        :class="className"
    >
        <div class="u-bleed">
            <asset
                :asset="asset"
                class="b-image-oversized__img"
            />
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'

import { gridColumnsClasses } from 'src/utils'

export default {
    name: 'BlockImageOversized',
    components: {
        Asset,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-image-oversized'

            classname += gridColumnsClasses(this.data.blockSettings[0])

            return classname
        },
        asset() {
            const asset = this.data.image
            return asset.length > 0 ? asset[0] : false
        },
    },
};

</script>

<style lang="scss">

.b-image-oversized {
}

.b-image-oversized__img {

    @media (min-width: $grid-max-width) {
        width: 100vw;
        left: calc( 50% - 50vw);
        height: vh(100);
        object-fit: cover;
        object-position: center;
        overflow: hidden;
    }
}

</style>
