import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'
import pages from './modules/pages'
import works from './modules/works'
import misc from './modules/misc'
import loader from './modules/loader'

// Vuex
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        global,
        pages,
        works,
        misc,
        loader,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
