<template>
    <span class="o-spacer"></span>
</template>

<script>

export default {
    name: 'Spacer'
};

</script>

<style lang="scss">

.o-spacer {
    display: block;
    width: 100%;
    height: 1.5rem;

    @media #{md("xs")} {
        height: 2.5rem;
    }

    @media #{md("sm")} {
        height: 4rem;
    }
}

</style>
