/*
** Craft GraphQL Snippets
*/

import { getAsset } from './assets'

/**********************************
 *
 *
 *  SEO
 *
 *
 **********************************/

const seoPage = `
    seoPage {
        title
        description
    }
`

const seoProject = `
seoProject {
    title
    description
}
`


/**********************************
 *
 *
 *  DEFAULT
 *
 *
 **********************************/

// Default data
const defaultData = `
title
id
slug
`

// Default entry
const entry = `
    ${defaultData}
    url
    uri
    sectionHandle
`


/**********************************
 *
 *
 *  MISC
 *
 *
 **********************************/


const pageSettings = `
pageSettings {
    ...on pageSettings_BlockType {
        darkMode
        showSocialMedias
    }
}
`



/**********************************
 *
 *
 *  Neo
 *
 *
 **********************************/


const headings = `
headings {
    ...on headings_BlockType {
        headline
        headlineClass
        headlineTag
        suptitle
    }
}
`

const siteColors = `
siteColors {
    ...on siteColors_BlockType {
        colorCode
        colorLabel
    }
}
`

// BLOCKS
const blockSettings = `
blockSettings {
    ...on blockSettings_BlockType {
        smCol
        mdCol
        lgCol
        textAlign
    }
}
`

const blockContentPage = `
...on layoutPage_blockContent_BlockType {
    typeHandle
    ${blockSettings}

    children {

        # Headline
        ...on layoutPage_blockContentHeadline_BlockType {
            typeHandle
            ${headings}
        }

        # Text
        ...on layoutPage_blockContentText_BlockType {
            typeHandle
            body
        }

        # List
        ...on layoutPage_blockContentList_BlockType {
            typeHandle

            # List
            list {
                ...on list_TableRow {
                    item
                }
            }

            # Cards
            cards {
                ...on cards_card_BlockType {
                    headline
                    suptitle
                    ${getAsset('image')}
                    squareImage: ${getAsset('image', "square")}
                }
            }
        }

        # Image
        ...on layoutPage_blockContentImage_BlockType {
            typeHandle
            ${getAsset('image')}
            roundedImage: ${getAsset('image', 'rounded')}
            rounded
        }

        # Buttons
        ...on layoutPage_blockContentButtons_BlockType {
            typeHandle
            buttons {
                ...on buttons_button_BlockType {
                    selectedEntry {
                        title
                        uri
                    }
                    outerLink
                    label
                    color
                    icon
                }
            }
        }

        # Video
        ...on layoutPage_blockContentVideo_BlockType {
            typeHandle
            video {
                ...on video_BlockType {
                    video_id
                    width
                    backgroundMode
                    hasControl
                    isLoop
                }
            }
        }
    }
}
`

const neoLayoutPage = `
layoutPage {

    # Section
    ...on layoutPage_blockContainer_BlockType {
        typeHandle
        id
        sectionFullWidth
        sectionHandle
        bgColor
        addWave

        # Children
        children {

            # Row
            ...on layoutPage_blockRow_BlockType {
                typeHandle
                alignX
                alignY
                responsiveGrid

                # Children
                children {
                    ${blockContentPage}

                    # Projects
                    ...on layoutPage_blockProjects_BlockType {
                        typeHandle
                        selectEntriesWork {
                            id
                        }
                        ${blockSettings}
                    }

                    # UpcomingProjects
                    ...on layoutPage_blockUpcomingProjects_BlockType {
                        typeHandle
                        ${headings}
                        selectEntriesWork {
                            id
                        }
                        ${blockSettings}
                    }

                    # Team
                    ...on layoutPage_blockTeam_BlockType {
                        typeHandle
                        selectEntriesMember {
                            id
                        }
                        ${blockSettings}
                        blockTeamDisplay {
                            ...on blockTeamDisplay_BlockType {
                                job
                                email
                                phone
                                photo
                            }
                        }
                    }

                    # Tools
                    ...on layoutPage_blockTools_BlockType {
                        typeHandle
                        headline
                        ${blockSettings}
                    }

                    # ColorSwatches
                    ...on layoutPage_blockColorSwatches_BlockType {
                        typeHandle
                        ${siteColors}
                        ${blockSettings}
                    }

                    # ImageGallery
                    ...on layoutPage_blockImageGallery_BlockType {
                        typeHandle
                        imageGallery {
                            ...on imageGallery_BlockType {
                                ${getAsset('image')}
                                width
                            }
                        }
                        ${blockSettings}
                    }

                    # MediasGallery
                    ...on layoutPage_blockMediasGallery_BlockType {
                        typeHandle
                        mediasGallery {
                            ...on mediasGallery_image_BlockType {
                                typeHandle
                                ${getAsset('source')}
                                width
                            }
                            ...on mediasGallery_video_BlockType {
                                typeHandle
                                video_id
                                width
                                backgroundMode
                                hasControl
                                isLoop
                                playInView
                            }
                        }
                        ${blockSettings}
                    }

                    # ImageOversized
                    ...on layoutPage_blockImageOversized_BlockType {
                        typeHandle
                        ${getAsset('image')}
                        ${blockSettings}
                    }

                    # ImagesSlider
                    ...on layoutPage_blockImagesSlider_BlockType {
                        typeHandle
                        imageSlider {
                            ...on imageSlider_BlockType {
                                ${getAsset('image')}
                                label
                            }
                        }
                        ${blockSettings}
                    }

                    # Instagram
                    ...on layoutPage_blockInstagram_BlockType {
                        typeHandle
                        ${headings}
                        ${blockSettings}
                    }

                    # Website
                    ...on layoutPage_blockWebsite_BlockType {
                        typeHandle
                        subtitle
                        externalLink
                        selectColor
                        color
                    }

                    # Spacer
                    ...on layoutPage_blockSpacer_BlockType {
                        typeHandle
                    }
                }
            }

            # Content
            ${blockContentPage}
        }
    }
}
`

const neoLayoutResults = `
layoutResult {

    # Section
    ...on layoutResult_blockContainer_BlockType {
        typeHandle
        id
        sectionFullWidth
        sectionHandle
        bgColor
        addWave

        # Children
        children {

            # Row
            ...on layoutResult_blockRow_BlockType {
                typeHandle
                alignX
                alignY
                responsiveGrid

                # Children
                children {

                    ...on layoutResult_blockContent_BlockType {
                        typeHandle
                        ${blockSettings}

                        children {

                            # Headline
                            ...on layoutResult_blockContentHeadline_BlockType {
                                typeHandle
                                ${headings}
                            }

                            # Text
                            ...on layoutResult_blockContentText_BlockType {
                                typeHandle
                                body
                            }

                            # Image
                            ...on layoutResult_blockContentImage_BlockType {
                                typeHandle
                                ${getAsset('image')}
                                roundedImage: ${getAsset('image', 'rounded')}
                                rounded
                            }

                            # Spacer
                            ...on layoutResult_blockSpacer_BlockType {
                                typeHandle
                            }

                            # Advanced
                            ...on layoutResult_blockResult_BlockType {
                                ${getAsset('image')}
                                imagePortrait: ${getAsset('image', 'portrait')}
                                subtitle
                                heading
                                selectColor
                                externalLink
                            }

                            ...on layoutResult_blockProjectTools_BlockType {
                                typeHandle
                            }

                            ...on layoutResult_blockAwards_BlockType {
                                typeHandle
                                awards {
                                    ...on awards_BlockType {
                                        ${getAsset('logo')}
                                        heading
                                        buttonLabel
                                        externalLink
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`




/**********************************
 *
 *
 *  WORK
 *
 *
 **********************************/


const workPartial = `
${entry}

...on work_project_Entry {
    # Card
    featured
    isUpcoming
    ${getAsset('image', 'cardProject')}
    ${getAsset('backgroundImage', 'cardProject')}
    imageSquare: ${getAsset('backgroundImage', 'square')}
    externalLink
    color
    hoverText
    hoverLight
    catchphrase
    children {
        title
        typeHandle
        ...on work_subProject_Entry {
            selectCategoryService {
                ${defaultData}
            }
        }
    }
}
`

const workSubproject = `
...on work_subProject_Entry {
    ${neoLayoutPage}
    selectCategoryService {
        ${defaultData}
    }
    ${getAsset('image')}
}
`

const workResults = `
...on work_results_Entry {
    ${neoLayoutResults}
}
`

//
export default {
    seoPage,
    seoProject,
    defaultData,
    pageSettings,
    workPartial,
    workSubproject,
    workResults,
    entry,
    headings,
    siteColors,
    neoLayoutPage,
    neoLayoutResults,
}
